import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
  loading: false,
  error: null,
  department: [],
  editDepartment: "",
  departmentParams:{
    page: 1,
    pageSize: 5,
  },
};

export const fetchDepartment = createAsyncThunk(
  "departmentMgt/fetchDepartment",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/Department${objToParams(getState()?.departmentMgt?.departmentParams)}`);
  }
);

export const createDepartment = createAsyncThunk(
  "departmentMgt/createDepartment",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Department`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


const departmentMgtSlice = createSlice({
  name: "departmentMgt",
  initialState,
  reducers: {
    setEditDepartment: (state, { payload }) => {
      state.editDepartment = payload;
    },
    setUpdateDepartmentPagination:(state, {payload}) => {
      state.departmentParams.page = payload;
      state.department = [];
    },
    setUpdateDepartmentPageSize:(state, {payload}) => {
      state.departmentParams.pageSize = payload;
      state.department = [];
    },

  },
  extraReducers: (builder) => {
    // get Department:
    builder.addCase(fetchDepartment.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchDepartment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.department = payload;
      state.error = null;
    });

    builder.addCase(fetchDepartment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create Department:
    builder.addCase(createDepartment.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createDepartment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createDepartment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const { setEditDepartment, setUpdateDepartmentPagination, setUpdateDepartmentPageSize } = departmentMgtSlice.actions;
export default departmentMgtSlice.reducer;