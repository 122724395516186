import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
  loading: false,
  error: null,
  nyscs: [],
  nyscDetails: "",
  nyscsParams:{
    status: "Pending",
    page: 1,
    pageSize: 5,
  },
};

export const fetchNYSCApplicationApproval = createAsyncThunk(
  "NYSCApplicationApproval/fetchNYSCApplicationApproval",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/Application/nyscs${objToParams(getState()?.NYSCApplicationApproval?.nyscsParams)}`,);
  }
);

export const fetchNYSCDetails = createAsyncThunk(
  "NYSCApplicationApproval/fetchNYSCDetails",
  async (id) => {
    return httpRequest(`${BASE_URL}/v2/Application/${id}/nysc-details`,);
  }
);

export const approveNYSCApplication = createAsyncThunk(
  "NYSCApplicationApproval/approveNYSCApplication",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Application/approve-nysc`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const NYSCApplicationApprovalSlice = createSlice({
  name: "NYSCApplicationApproval",
  initialState,
  reducers: {
    setNYSCDetails: (state, { payload }) => {
      state.nyscDetails = payload;
    },

    setUpdateNyscsStatus:(state, {payload}) => {
      state.nyscsParams.status = payload;
      state.nyscs = [];
    },
    setUpdateNyscsPagination:(state, {payload}) => {
      state.nyscsParams.page = payload;
      state.nyscs = [];
    },
    setUpdateNyscsPageSize:(state, {payload}) => {
      state.nyscsParams.pageSize = payload;
      state.nyscs = [];
    },
  },
  extraReducers: (builder) => {

    //  get nysc applications:
    builder.addCase(fetchNYSCApplicationApproval.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNYSCApplicationApproval.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.nyscs = payload;
      state.error = null;
    });
    builder.addCase(fetchNYSCApplicationApproval.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // get NYSC details:
    builder.addCase(fetchNYSCDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNYSCDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.nyscDetails = payload;
      state.error = null;
    });
    builder.addCase(fetchNYSCDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //  approve nysc application:
    builder.addCase(approveNYSCApplication.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(approveNYSCApplication.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(approveNYSCApplication.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }

});

export const { setNYSCDetails, setUpdateNyscsStatus, setUpdateNyscsPagination, setUpdateNyscsPageSize } = NYSCApplicationApprovalSlice.actions;
export default NYSCApplicationApprovalSlice.reducer