import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex,Text, Button, Menu, MenuButton, MenuItem, MenuList, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import Create from '../components/layout/Create';
import {BsThreeDotsVertical} from 'react-icons/bs';
import { useDispatch, useSelector} from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import { PiExport } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import {setExamDietDetails, setEditExamDiet, fetchExamDiet,setUpdateExamDietPagination, setUpdateExamDietPageSize} from "../redux/slices/examDietPeriodSlice";
import SortBy from '../components/SortBy';
import { checkPermissions} from '../components/CheckPermission';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from '../components/loaders/DotLoader';
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";
import CustomTablePage2 from '../components/CustomTablePage2';

const ExamDiet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const examDietData = useSelector((state) => state?.examDietPeriod);

  const getAllExamDiet = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchExamDiet());
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Exam Diet, please check your request/network");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Exam Diet, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllExamDiet();
  }, [dispatch, getAllExamDiet, examDietData?.examDietParams]);

  const addDietPermission = checkPermissions( 'Exam Setup', 'Exam Diet', 'Create', 'Granted' );
  const editDietPermission = checkPermissions( 'Exam Setup', 'Exam Diet', 'Edit', 'Granted' );
  const deleteDietPermission = checkPermissions( 'Exam Setup', 'Exam Diet', 'Delete', 'Granted' );

  const createData = {
    onClick: () => { dispatch(setFormModal({status:true, title: "Add Diet ", type:"addDiet"})); },
    createText: "Add Diet ",
  }
  const handleUpdate = (item) => {
    dispatch(setExamDietDetails(item));
    navigate(`/examsetup/examdiet/exam-period?dietId=${item?.id}`);
  }

  const handlePagination = (page) =>{
    dispatch(setUpdateExamDietPagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateExamDietPageSize(size));
  };
  const handleEdit = (item) => {
    dispatch(setEditExamDiet(item));
    dispatch(setFormModal({status:true, title: "Edit Diet", type:"editDiet"}));
  }
  const handleDelete = async (item) => {
    setError('');
   try {
      const response = await httpRequest(`${BASE_URL}/v2/ExamDiet/${item?.id}/delete`,
      'post', 
    );
    if(response?.isSuccess === true){
      setError('');
      dispatch(fetchExamDiet());
      dispatch(setloadTost({ title: 'Exam Diet Deleted', description: `${item?.name}  Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
   }else{
      setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete Exam Diet");
    }
    
   } catch (error) {
    setError(error?.response?.message || "Failed to delete Exam Diet");
   }

}

  const tableProps = {
    data: examDietData?.examDiet?.data,
    title: {
      diet: "Diet",
      status:"Status",
      examPeriod:" ",
      Action:'',
    },
    dataKeys: [
      "diet",
      "status",
      "examPeriod",
      "Action",
    ],
    handlePagination,
    handlePageSize,
    itemsPerPage:examDietData?.examDiet?.currentPageRecord,
    totalRecord: examDietData?.examDiet?.totalRecord,
    pageNumber: examDietData?.examDietParams?.page,
    nextPage: examDietData?.examDiet?.nextPage,
    previousPage: examDietData?.examDiet?.previousPage,
    pageSize: examDietData?.examDietParams?.pageSize,
    transformRow: (item) => ({
      diet: item &&  <Box style={{cursor:'pointer'}}>{item?.name}</Box>,
      status: item &&  <Box style={{cursor:'pointer'}} color={item?.isActive ? "#3064FC" : "#D11A2A"}>{item?.isActive ? "Active" : "Inactive"}</Box>,
      examPeriod: item && <Box style={{cursor:'pointer'}} color={'#3064FC'} textDecoration={'underline'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} onClick={() => handleUpdate(item)}>View Exam Period</Box>,
      Action: item && (
        <Menu>
          {
            !editDietPermission && !deleteDietPermission ? null : (
              <MenuButton>
              <BsThreeDotsVertical />
            </MenuButton>
            )
          }
          <MenuList >
            {
              editDietPermission? (
                <MenuItem onClick={() => handleEdit(item)}>Edit </MenuItem>
              ) : null
            }
            {
              deleteDietPermission? (
                <MenuItem onClick={() => handleDelete(item)}> Delete </MenuItem>
              ) : null
            }
          </MenuList>
        </Menu>
      )
    }),
  };

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}> Exam Diet </Text>
        <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
          {
            addDietPermission? (
              <Create {...createData} />
            ) : null
          }
          <SortBy />
          <Button leftIcon={<PiExport style={{width: "15px", height: "15px"}} />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
            fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
          >
            Export
          </Button>
        </Flex>
      </Flex>
      {
        loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ):(
          <>
            {
            error ? (
              <Alert status="error" className="mt-3" mb={3}>
                  <AlertIcon />
                  <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
              </Alert>
              ) 
              : (<CustomTablePage2 {...tableProps} />)
            }
          </>
        )
      }

    </Box>
  )
}

export default ExamDiet;