

export const objToParams = (paramsObj) => {
    let query = "";
    for (let key in paramsObj) {
      if (
        paramsObj[key] !== undefined &&
        paramsObj[key] !== null &&
        paramsObj[key] !== ""
      ) {
        const searchParam = `${key}=${paramsObj[key]}`;
        query += query === "" ? `?${searchParam}` : `&${searchParam}`;
      }
    }
    return query;
  };



  export const objToParamsContinuation = (paramsObj) => {
    let query = "";
    for (let key in paramsObj) {
      if (
        paramsObj[key] !== undefined &&
        paramsObj[key] !== null &&
        paramsObj[key] !== ""
      ) {
        const searchParam = `${key}=${paramsObj[key]}`;
        query += `&${searchParam}`;
      }
    }
    return query;
  };