import React, { useEffect, useState, useCallback } from 'react';
import { format, isToday} from 'date-fns';
import { Box, Text, Flex, AvatarGroup, Avatar, Divider, Menu, MenuButton, MenuList, MenuItem, Alert,AlertIcon, AlertTitle  } from '@chakra-ui/react';
import { MdOutlineFileUpload } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import Create from '../components/layout/Create';
import { checkPermissions} from '../components/CheckPermission';
import SortBy from '../components/SortBy';
import {fetchAnnouncement, setEditAnnouncement, setUpdateAnnouncementsPagination, setUpdateAnnouncementsPageSize} from '../redux/slices/announcementSlice';
import {setloadTost} from "../redux/slices/toastSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import { clearsearchFilter } from '../redux/slices/searchFilterSlice';
import DotLoader from '../components/loaders/DotLoader';
import PaginationMain from '../components/layout/PaginationMain';

const Announcement = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const announcementData  = useSelector(state => state?.announcement);

  const getAllAnnouncement = useCallback( async() => {
    setError("");
    setLoading(true);
    try {
      const response = await dispatch(fetchAnnouncement());
      // console.log('announcement response', response);
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get announcement, please check your request/network");
      }
      
    } catch (error) {
      setLoading(false);
      setError(error?.payload?.message || "Failed to get announcement, please check your network");
    }finally{
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllAnnouncement();
  }, [dispatch, getAllAnnouncement, announcementData?.announcementsParams]);

  const addAnnouncementPermission = checkPermissions( 'Announcement', 'Announcement', 'Create', 'Granted' );
  const editAnnouncementPermission = checkPermissions( 'Announcement', 'Announcement', 'Edit', 'Granted' );
  const deleteAnnouncementPermission = checkPermissions( 'Announcement', 'Announcement', 'Delete', 'Granted' );

  const createData = {
    onClick: () => { dispatch(setFormModal({ status: true, title: "Add Announcement", type: "addAnnouncement", })); },
    createText: "Add Announcement",
  };

  const handlePagination = (page) =>{
    dispatch(setUpdateAnnouncementsPagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateAnnouncementsPageSize(size));
  };

  const pageData ={
    handlePagination,
    handlePageSize,
    itemsPerPage:announcementData?.announcements?.currentPageRecord,
    totalRecord: announcementData?.announcements?.totalRecord,
    currentPage: announcementData?.announcementsParams?.page,
    nextPage: announcementData?.announcements?.nextPage,
    previousPage: announcementData?.announcements?.previousPage,
    pageSize: announcementData?.announcementsParams?.pageSize,
  }

  const handleEdit = (item) => {
    dispatch(setEditAnnouncement(item));
    dispatch(setFormModal({ status: true, title: "Edit Announcement", type: "editAnnouncement" }));
  }

  const handleDelete = async (item) => {
    setError("");
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Announcement/${item?.id}/delete`,
        "post",
      );
      // console.log(response);
      if (response?.isSuccess === true) {
        setError('');
        dispatch(fetchAnnouncement());
        dispatch(setloadTost({ title: 'Announcement Deleted', description: 'Announcement deleted successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      } else {
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete announcement");
      }
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to delete announcement, please check your network");
    }
  }

  const avatarData = [
    { avatarName: 'Ryan Florence', img: 'https://bit.ly/ryan-florence' },
    { avatarName: 'Segun Adebayo', img: 'https://bit.ly/sage-adebayo' },
    { avatarName: 'Kent Dodds', img: 'https://bit.ly/kent-c-dodds' },
    { avatarName: 'Prosper Otemuyiwa', img: 'https://bit.ly/prosper-baba' },
    { avatarName: 'Christian Nwamba', img: 'https://bit.ly/code-beast' },
    { avatarName: 'Ryan Florence', img: 'https://bit.ly/ryan-florence' },
  ]
  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={['column', 'column', 'row']} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'20px'} fontWeight={'700'} color={'#D11A2A'}>Announcements</Text>
        <Flex flexDirection={['column', 'column', 'row']}  justifyContent={'center'} alignItems={'center'} gap={'20px'} >
          {
            addAnnouncementPermission ? (
              <Create {...createData} />
            ) : null
          }
          <SortBy />
          <Flex alignItems={'center'} _hover={{ color: '#D11A2A' }} cursor={'pointer'}>
            <MdOutlineFileUpload />
            <Text fontSize={'14px'} fontWeight={500} color={'#404040'} w={'100px'} _hover={{ color: '#D11A2A' }}>EXPORT</Text>
          </Flex>
        </Flex>
      </Flex>
      {
        loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ): (
          <>
          {
          error &&(
          <Alert status="error" className="mt-3" mb={3}>
              <AlertIcon />
              <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
          </Alert>
          )
          }
          <Flex alignItems={'flex-start'}  px={'30px'} pt={'10px'} pb={'10px'} mb={'20px'} bgColor={'#D734390A'} >
            <Text fontSize={'14px'} color={'#222B45'} fontWeight={700} w={'20%'}>All</Text>
            <Text fontSize={'14px'} color={'#B4B1B1'} fontWeight={700} w={'80%'} textAlign={'start'} pl={3}>Today</Text>
          </Flex>
          {
            announcementData?.announcements?.data && announcementData?.announcements?.data.map((item, index) => (
              <>
                <Flex key={index} px={['20px', '30px', '30px']} gap={5} overflow={'auto'} alignItems={'center'} >
                  <Flex flexDirection={'column'} mt={2} w={'18%'} >
                    <Text color={'#222B45'} fontWeight={400} mb={2} fontSize={'14px'}>
                      {item.createdOn ? (isToday(new Date(item.createdOn)) ? 'Today' 
                        : format(new Date(item.createdOn), "MMMM dd yyyy")) 
                      : null }
                    </Text>
                    <Text color={'#222B45'} fontWeight={400} mb={2} fontSize={'14px'}>{item.createdOn && format(new Date(item.createdOn), "hh:mm a") }</Text>
                  </Flex>
                  <Divider orientation='vertical' mt={2} border={'1px solid #B4B1B1'} height={20} />
                  <Flex flexDirection={'column'} w={'52%'} alignItems={'flex-start'} >
                    <Text color={'#222B45'} fontWeight={700} mb={2} fontSize={'14px'}>{item.title}</Text>
                    <Text fontSize={'12px'} color={'#222B45'} fontWeight={400}>{item.description}</Text>
                  </Flex>
                  <Flex  alignItems={'center'} gap={'100px'} w={'30%'}>
                    <Flex flexDirection={'column'} alignItems={'center'} >
                      <Text color={'#222B45'} fontWeight={400} mb={2} fontSize={'12px'}>Viewed by</Text>
                      <AvatarGroup key={index} size='sm' max={3}>
                        {
                          avatarData.map((item, index) => (
                            <Avatar name={item.name} src={item.img} />
                          ))
                        }
                      </AvatarGroup>
                    </Flex>
                    <Menu >
                      {
                        !editAnnouncementPermission && !deleteAnnouncementPermission ? null : (
                          <MenuButton>
                            <BsThreeDotsVertical />
                          </MenuButton>
                        )
                      }
                      <MenuList >
                        {
                          editAnnouncementPermission ? (
                            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
                          ) : null
                        }
                        {
                          deleteAnnouncementPermission ? (
                            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
                          ) : null
                        }
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
                <Divider mt={5} border={'1px solid #E3E3E3'} mb={5} />
              </>
            ))
          }
          <Flex justifyContent={'center'} alignItems={'center'}>
            <PaginationMain {...pageData}/>
          </Flex>
        </>
        )
      }
    </Box>
  )
}

export default Announcement;
