import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
    loading: false,
    error: null,
    entryQualificationType: [],
    applicableExemption: [],
    entryQualificationTypeParams:{
        page: 1,
        pageSize: 5,
    },
}

export const AddEntryQualificationType = createAsyncThunk(
    "entryQualificationType/AddEntryQualificationType",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/EntryQualificationType`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const fetchEntryQualificationType = createAsyncThunk(
    "entryQualificationType/fetchEntryQualificationType",
    async (arg, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/EntryQualificationType${objToParams(getState()?.entryQualificationType?.entryQualificationTypeParams)}`);
    }
);
export const editEntryQualificationType = createAsyncThunk(
    "entryQualificationType/editEntryQualificationType",
    async ( {id, payload}, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/EntryQualificationType/${id}/update`,
                "post",
                payload
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const deleteEntryQualificationType = createAsyncThunk(
    "entryQualificationType/deleteEntryQualificationType",
    async ( id, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/EntryQualificationType/${id}/delete`,
                "post",
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const AddApplicableExemptions = createAsyncThunk(
    "entryQualificationType/AddApplicableExemptions",
    async ({id, arg}, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/EntryQualificationType/${id}/applicable-exemption`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const fetchApplicableExemption = createAsyncThunk(
    "entryQualificationType/fetchApplicableExemption",
    async (id, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/EntryQualificationType/${id}/applicable-exemptions${objToParams(getState()?.entryQualificationType?.entryQualificationTypeParams)}`);
    }
);

export const deleteApplicableExemption = createAsyncThunk(
    "entryQualificationType/deleteApplicableExemption",
    async ( id, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/EntryQualificationType/applicable-exemption/${id}/delete`,
                "post",
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const entryQualificationTypeSlice = createSlice({
    name: "entryQualificationType",
    initialState,
    reducers: {
        setUpdateEntryQualificationTypePagination:(state, {payload}) => {
            state.entryQualificationTypeParams.page = payload;
            state.entryQualificationType = [];
        },
        setUpdateEntryQualificationTypePageSize:(state, {payload}) => {
        state.entryQualificationTypeParams.pageSize = payload;
        state.entryQualificationType = [];
        },

        setUpdateApplicableExemptionPagination:(state, {payload}) => {
            state.entryQualificationTypeParams.page = payload;
            state.applicableExemption = [];
        },
        setUpdateApplicableExemptionPageSize:(state, {payload}) => {
        state.entryQualificationTypeParams.pageSize = payload;
        state.applicableExemption = [];
        },
    },  
    extraReducers: (builder) => {
        // Add EntryQualificationType:
        builder.addCase(AddEntryQualificationType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(AddEntryQualificationType.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description || null;
        });
        builder.addCase(AddEntryQualificationType.rejected, (state, { payload}) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // get all entryQualificationType:
        builder.addCase(fetchEntryQualificationType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchEntryQualificationType.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.entryQualificationType = payload;
            state.error = payload?.error?.description || null;
        });
        builder.addCase(fetchEntryQualificationType.rejected, (state, { payload}) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // edit entryQualificationType:
        builder.addCase(editEntryQualificationType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editEntryQualificationType.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description || null;
        });
        builder.addCase(editEntryQualificationType.rejected, (state, { payload}) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // delete entryQualificationType:
        builder.addCase(deleteEntryQualificationType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteEntryQualificationType.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description || null;
        });
        builder.addCase(deleteEntryQualificationType.rejected, (state, { payload}) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // Add Applicable Exemption:
        builder.addCase(AddApplicableExemptions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(AddApplicableExemptions.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description || null;
        });
        builder.addCase(AddApplicableExemptions.rejected, (state, { payload}) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // get all Applicable Exemption:
        builder.addCase(fetchApplicableExemption.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchApplicableExemption.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.applicableExemption = payload;
            state.error = payload?.error?.description || null;
        });
        builder.addCase(fetchApplicableExemption.rejected, (state, { payload}) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // delete Applicable Exemption:
        builder.addCase(deleteApplicableExemption.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteApplicableExemption.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description || null;
        });
        builder.addCase(deleteApplicableExemption.rejected, (state, { payload}) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });
    } 
});


export default entryQualificationTypeSlice.reducer;
export const { setUpdateEntryQualificationTypePagination, setUpdateEntryQualificationTypePageSize, setUpdateApplicableExemptionPagination, setUpdateApplicableExemptionPageSize } = entryQualificationTypeSlice.actions;