import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
  loading: false,
  error: null,
  examCentre: [],
  editExamCentre: "",
  examCentreParams:{
    page: 1,
    pageSize: 5,
  },
};

export const fetchExamCentre = createAsyncThunk(
  "examCentre/fetchExamCentre",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/ExamCentre${objToParams(getState()?.examCentre?.examCentreParams)}`);
  }
);

export const createExamCentre = createAsyncThunk(
  "examCentre/createExamCentre",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/ExamCentre`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


const examCentreSlice = createSlice({
  name: "examCentre",
  initialState,
  reducers: {
    setEditExamCentre: (state, { payload }) => {
      state.editExamCentre = payload;
    },
    setUpdateExamCentrePagination:(state, {payload}) => {
      state.examCentreParams.page = payload;
      state.examCentre = [];
    },
    setUpdateExamCentrePageSize:(state, {payload}) => {
      state.examCentreParams.pageSize = payload;
      state.examCentre = [];
    },

  },
  extraReducers: (builder) => {
    // get examCentre:
    builder.addCase(fetchExamCentre.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExamCentre.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.examCentre = payload;
      state.error = null;
    });

    builder.addCase(fetchExamCentre.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create examCentre:
    builder.addCase(createExamCentre.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createExamCentre.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createExamCentre.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const { setEditExamCentre, setUpdateExamCentrePagination, setUpdateExamCentrePageSize } = examCentreSlice.actions;
export default examCentreSlice.reducer;