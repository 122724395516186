import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
  loading: false,
  error: null,
  examResult: [],
  editExamResult: "",
  examResultDetails: "",
  examResultParams:{
    page: 1,
    pageSize: 5,
  },
};

export const fetchExamResult = createAsyncThunk(
  "examResult/fetchExamResult",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/ExamResult${objToParams(getState()?.examResult?.examResultParams)}`);
  }
);

export const createExamResult = createAsyncThunk(
  "examResult/createExamResult",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/ExamResult`,
        "post",
        arg,
        null,
        "multipart/form-data",
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const examResultSlice = createSlice({
  name: "examResult",
  initialState,
  reducers: {
    setEditExamResult: (state, { payload }) => {
      state.editExamResult = payload;
    },
    setExamResultDetails: (state, { payload }) => {
      state.examResultDetails = payload;
    },

    setUpdateExamResultPagination:(state, {payload}) => {
      state.examResultParams.page = payload;
      state.examResult = [];
    },
    setUpdateExamResultPageSize:(state, {payload}) => {
      state.examResultParams.pageSize = payload;
      state.examResult = [];
    },

  },
  extraReducers: (builder) => {
    // get examResult:
    builder.addCase(fetchExamResult.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExamResult.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.examResult = payload;
      state.error = null;
    });

    builder.addCase(fetchExamResult.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create examResult:
    builder.addCase(createExamResult.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createExamResult.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createExamResult.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const { setEditExamResult, setExamResultDetails, setUpdateExamResultPagination, setUpdateExamResultPageSize } = examResultSlice.actions;
export default examResultSlice.reducer;