import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
    bioData:[],
    pendingBioDataApproval: [],
    duesManagement: [],
    duesManagementMemberProfile: [],
    duesManagementMemberDues:[],
    duesManagementPaymentHistory: [],
    memberDues: [],
    loading: false,
    bioDataDetail: "",
    error: null,
    duesManagementParams:{
        page: 1,
        pageSize: 5,
    },
    duesManagementHistoryParams:{
        page: 1,
        pageSize: 5,
    },
};

export const fetchBioData = createAsyncThunk(
    "membershipManagement/fetchBioData",
    async ({memberNumber, memberCategory }) => {
        return httpRequest(`${BASE_URL}/v2/MembershipManagement/BioData?memberNumber=${memberNumber}&memberCategory=${memberCategory}`);
    }
);

export const fetchPendingBioDataApproval = createAsyncThunk(
    "membershipManagement/fetchPendingBioDataApproval",
    async ( arg, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/MembershipManagement/PendingBioDataApproval${objToParams(getState()?.membershipManagement?.duesManagementParams)}`);
    }
)

export const updateBioData = createAsyncThunk(
    "membershipManagement/updateBioData",
    async ({memberId, arg}, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/MembershipManagement/BioDataUpdate/${memberId}`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const updateBioDataStatus = createAsyncThunk(
    "membershipManagement/updateBioDataStatus",
    async ( bioDataId, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/MembershipManagement/updateBioDataStatus/${bioDataId}/update`,
                "post",
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const fetchDuesManagement = createAsyncThunk(
    "membershipManagement/fetchDuesManagement",
    async (arg, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/DuesManagement${objToParams(getState()?.membershipManagement?.duesManagementParams)}`);
    }
)

export const fetchDuesManagementMemberProfile = createAsyncThunk(
    "membershipManagement/fetchDuesManagementMemberProfile",
    async (memberId) => {
        return httpRequest(`${BASE_URL}/v2/DuesManagement/${memberId}/profile`);
    }
)

export const fetchDuesManagementMemberDues= createAsyncThunk(
    "membershipManagement/fetchDuesManagementMemberDues",
    async (memberId, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/DuesManagement/${memberId}${objToParams(getState()?.membershipManagement?.duesManagementParams)}`);
    }
)

export const fetchDuesManagementPaymentHistory = createAsyncThunk(
    "membershipManagement/fetchDuesManagementPaymentHistory",
    async (memberId, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/DuesManagement/${memberId}/payment-history${objToParams(getState()?.membershipManagement?.duesManagementHistoryParams)}`);
    }
)

export const fetchMemberDues = createAsyncThunk(
    "membershipManagement/fetchMemberDues",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Dues?Page=1&PageSize=1000`);
    }
)

const membershipManagementSlice = createSlice({
    name: "membershipManagement",
    initialState,
    reducers: {
        setBioDataDetail: (state, { payload }) => {
            state.bioDataDetail = payload;
        },     
        setUpdateDuesManagementPagination:(state, {payload}) => {
            state.duesManagementParams.page = payload;
            state.duesManagement = [];
        },
        setUpdateDuesManagementPageSize:(state, {payload}) => {
            state.duesManagementParams.pageSize = payload;
            state.duesManagement = [];
        },

        setUpdateDuesManagementMemberDuesPagination:(state, {payload}) => {
            state.duesManagementParams.page = payload;
            state.duesManagementMemberDues = [];
        },
        setUpdateDuesManagementMemberDuesPageSize:(state, {payload}) => {
            state.duesManagementParams.pageSize = payload;
            state.duesManagementMemberDues = [];
        },

        setUpdateDuesManagementPaymentHistoryPagination:(state, {payload}) => {
            state.duesManagementHistoryParams.page = payload;
            state.duesManagementPaymentHistory = [];
        },
        setUpdateDuesManagementPaymentHistoryPageSize:(state, {payload}) => {
            state.duesManagementHistoryParams.pageSize = payload;
            state.duesManagementPaymentHistory = [];
        },

        setUpdatePendingBioDataApprovalPagination:(state, {payload}) => {
            state.duesManagementParams.page = payload;
            state.pendingBioDataApproval = [];
        },
        setUpdatePendingBioDataApprovalPageSize:(state, {payload}) => {
            state.duesManagementParams.pageSize = payload;
            state.pendingBioDataApproval = [];
        },
        // setUpdatePendingBioDataApprovalPagination, setUpdatePendingBioDataApprovalPageSize 
        
    },
    extraReducers: (builder) => {
        // get Biodata:
        builder.addCase(fetchBioData.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchBioData.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.bioData = payload;
            state.error = null;
        });

        builder.addCase(fetchBioData.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // update Biodata:
        builder.addCase(updateBioData.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateBioData.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(updateBioData.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        }); 

        // get Pending Biodata Approval:
        builder.addCase(fetchPendingBioDataApproval.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchPendingBioDataApproval.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.pendingBioDataApproval = payload;
            state.error = null;
        });

        builder.addCase(fetchPendingBioDataApproval.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // update Biodata Status:
        builder.addCase(updateBioDataStatus.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateBioDataStatus.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(updateBioDataStatus.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

         // get dues management
         builder.addCase(fetchDuesManagement.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchDuesManagement.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.duesManagement = payload;
            state.error = null;
        });

        builder.addCase(fetchDuesManagement.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

          // get dues management member profile
          builder.addCase(fetchDuesManagementMemberProfile.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchDuesManagementMemberProfile.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.duesManagementMemberProfile = payload;
            state.error = null;
        });

        builder.addCase(fetchDuesManagementMemberProfile.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

          // get dues management member dues
          builder.addCase(fetchDuesManagementMemberDues.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchDuesManagementMemberDues.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.duesManagementMemberDues = payload;
            state.error = null;
        });

        builder.addCase(fetchDuesManagementMemberDues.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

             // get dues management member dues
             builder.addCase(fetchDuesManagementPaymentHistory.pending, (state) => {
                state.loading = true;
            });
    
            builder.addCase(fetchDuesManagementPaymentHistory.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.duesManagementPaymentHistory = payload;
                state.error = null;
            });
    
            builder.addCase(fetchDuesManagementPaymentHistory.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload?.error?.description;
            });
        
                   // get member dues
          builder.addCase(fetchMemberDues.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchMemberDues.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.memberDues = payload;
            state.error = null;
        });

        builder.addCase(fetchMemberDues.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

    }
});

export const { setBioDataDetail, setUpdateDuesManagementPageSize, setUpdateDuesManagementPagination, setUpdateDuesManagementMemberDuesPagination, setUpdateDuesManagementMemberDuesPageSize, setUpdateDuesManagementPaymentHistoryPagination, setUpdateDuesManagementPaymentHistoryPageSize, setUpdatePendingBioDataApprovalPagination, setUpdatePendingBioDataApprovalPageSize  } = membershipManagementSlice.actions;
export default membershipManagementSlice.reducer;