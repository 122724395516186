import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
  loading: false,
  error: null,
  examCategory: [],
  examSubject: [],
  examCategoryDetails: "",
  editExamCategory: "",
  editExamSubject: "",
  examCategoryParams:{
    page: 1,
    pageSize: 5,
  },
  examSubjectParams:{
    page: 1,
    pageSize: 5,
  },
};

export const fetchExamCategory = createAsyncThunk(
  "examCategorySubject/fetchExamCategory",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/ExamCategory${objToParams(getState()?.examCategorySubject?.examCategoryParams)}`);
  }
);

export const fetchExamSubject = createAsyncThunk(
  "examCategorySubject/fetchExamSubject",
  async (id, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/ExamCategory/${id}/subject${objToParams(getState()?.examCategorySubject?.examSubjectParams)}`);
  }
);

export const createExamCategory = createAsyncThunk(
    "examCategorySubject/createExamCategory",
    async (arg, { rejectWithValue }) => {
      try {
        const response = await httpRequest(
          `${BASE_URL}/v2/ExamCategory`,
          "post",
          arg
        );
        if (response?.response?.data) {
          throw response;
        }
        return response;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
);

export const createExamSubject = createAsyncThunk(
    "examCategorySubject/createExamSubject",
    async ({arg, id}, { rejectWithValue }) => {
      try {
        const response = await httpRequest(
            `${BASE_URL}/v2/ExamCategory/${id}/subject`,
          "post",
          arg
        );
        if (response?.response?.data) {
          throw response;
        }
        return response;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
);


const examCategorySubjectSlice = createSlice({
  name: "examCategorySubject",
  initialState,
  reducers: {
    setExamCategoryDetails: (state, { payload }) => {
      state.examCategoryDetails = payload;
    },
    setEditExamCategory: (state, { payload }) => {
      state.editExamCategory = payload;
    },
    setEditExamSubject: (state, { payload }) => {
      state.editExamSubject= payload;
    },
    setUpdateExamCategoryPagination:(state, {payload}) => {
      state.examCategoryParams.page = payload;
      state.examCategory = [];
    },
    setUpdateExamCategoryPageSize:(state, {payload}) => {
      state.examCategoryParams.pageSize = payload;
      state.examCategory = [];
    },
    setUpdateExamSubjectPagination:(state, {payload}) => {
      state.examSubjectParams.page = payload;
      state.examSubject = [];
    },
    setUpdateExamSubjectPageSize:(state, {payload}) => {
      state.examSubjectParams.pageSize = payload;
      state.examSubject = [];
    },

  },
  extraReducers: (builder) => {
    // get examCategory:
    builder.addCase(fetchExamCategory.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExamCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.examCategory = payload;
      state.error = null;
    });

    builder.addCase(fetchExamCategory.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // get examSubject:
    builder.addCase(fetchExamSubject.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExamSubject.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.examSubject = payload;
      state.error = null;
    });

    builder.addCase(fetchExamSubject.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create examCategory:
    builder.addCase(createExamCategory.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createExamCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createExamCategory.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create examSubject:
    builder.addCase(createExamSubject.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createExamSubject.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createExamSubject.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const { setExamCategoryDetails, setEditExamCategory, setEditExamSubject, setUpdateExamCategoryPagination, setUpdateExamCategoryPageSize, setUpdateExamSubjectPagination, setUpdateExamSubjectPageSize} = examCategorySubjectSlice.actions;
export default examCategorySubjectSlice.reducer;