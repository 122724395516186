import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import Create from "../components/layout/Create";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { PiExport } from "react-icons/pi";
import { checkPermissions } from "../components/CheckPermission";
import SortBy from "../components/SortBy";
import {
  fetchExamResult,
  setEditExamResult,
  setExamResultDetails,
  setUpdateExamResultPagination,
  setUpdateExamResultPageSize,
} from "../redux/slices/examResultSlice";
import { clearsearchFilter } from "../redux/slices/searchFilterSlice";
import DotLoader from "../components/loaders/DotLoader";
import { BASE_URL } from "../config/settings";
import { setloadTost } from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";
import CustomTablePage2 from "../components/CustomTablePage2";
import {
  fetchLookupExamCategory,
  fetchLookupExamDiet,
} from "../redux/slices/lookupSlice";

const ExamResult = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const uploadResultData = useSelector((state) => state?.examResult);

  const downloadCSV = (headers, body) => {
    const csvContent = [
      headers.join(","),
      ...body.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Exam_Result_Template.csv";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const header = ["StudentNumber", "SubjectCode", "Score", "Grade"];
  const body = [[]];

  const handleDownloadTemplate = () => {
    downloadCSV(header, body);
  };

  const getAllExamResult = useCallback(async () => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchExamResult());

      if (response?.payload?.name === "AxiosError") {
        setLoading(false);
        setError(
          response?.payload?.response?.data?.errors?.map((errors) => errors) ||
            response?.payload?.response?.data?.error?.description ||
            "Failed to get Exam result, please check your request/network"
        );
      }
      setLoading(false);
    } catch (error) {
      setError(
        error?.payload?.message ||
          "Failed to get Exam result, please check your network"
      );
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllExamResult();
    dispatch(fetchLookupExamCategory());
    dispatch(fetchLookupExamDiet());
  }, [dispatch, getAllExamResult, uploadResultData?.examResultParams]);

  const addResultPermission = checkPermissions(
    "Exam Setup",
    "Exam Result",
    "Create",
    "Granted"
  );
  const editResultPermission = checkPermissions(
    "Exam Setup",
    "Exam Result",
    "Edit",
    "Granted"
  );
  const deleteResultPermission = checkPermissions(
    "Exam Setup",
    "Exam Result",
    "Delete",
    "Granted"
  );

  const createData = {
    onClick: () => {
      dispatch(
        setFormModal({
          status: true,
          title: "Upload Result ",
          type: "uploadResult",
        })
      );
    },
    createText: "Upload Result ",
  };

  const handlePagination = (page) => {
    dispatch(setUpdateExamResultPagination(page));
  };
  const handlePageSize = (size) => {
    dispatch(setUpdateExamResultPageSize(size));
  };

  const handleUpdate = (item) => {
    dispatch(setExamResultDetails(item));
    dispatch(
      setFormModal({
        status: true,
        title: "Exam Result Details",
        type: "examResultDetails",
      })
    );
  };
  const handleEdit = (item) => {
    dispatch(setEditExamResult(item));
    dispatch(
      setFormModal({ status: true, title: "Edit Result", type: "editResult" })
    );
  };

  const handleDelete = async (item) => {
    setError("");
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/ExamResult/${item?.id}/delete`,
        "post"
      );

      if (response?.isSuccess === true) {
        setError("");
        dispatch(fetchExamResult());
        dispatch(
          setloadTost({
            title: "Exam Result Deleted",
            description: `${item?.examYear} ${item?.examDiet} ${item?.examCategory}   Deleted successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          })
        );
      } else {
        setError(
          response?.response?.data?.errors?.map((errors) => errors) ||
            response?.response?.data?.error?.description ||
            "Failed to delete item"
        );
      }
    } catch (error) {
      setError(error?.response?.message || "Failed to delete item");
    }
  };

  const tableProps = {
    data: uploadResultData?.examResult?.data,
    title: {
      examYear: "Exam Year",
      examDiet: "Diet",
      category: "Exam Category",
      examPeriod: " ",
      Action: "",
    },
    dataKeys: ["examYear", "examDiet", "category", "examPeriod", "Action"],
    handlePagination,
    handlePageSize,
    itemsPerPage: uploadResultData?.examResult?.currentPageRecord,
    totalRecord: uploadResultData?.examResult?.totalRecord,
    pageNumber: uploadResultData?.examResultParams?.page,
    nextPage: uploadResultData?.examResult?.nextPage,
    previousPage: uploadResultData?.examResult?.previousPage,
    pageSize: uploadResultData?.examResultParams?.pageSize,

    transformRow: (item) => ({
      examYear: item && (
        <Box style={{ cursor: "pointer" }}>{item?.examYear}</Box>
      ),
      examDiet: item && (
        <Box style={{ cursor: "pointer" }}>{item?.examDiet}</Box>
      ),
      category: item && (
        <Box style={{ cursor: "pointer" }}>{item?.examCategory}</Box>
      ),
      examPeriod: item && (
        <Box
          style={{ cursor: "pointer" }}
          color={"#3064FC"}
          textDecoration={"underline"}
          fontSize={"14px"}
          fontWeight={"500"}
          lineHeight={"16px"}
          onClick={() => handleUpdate(item)}
        >
          View File
        </Box>
      ),
      Action: item && (
        <Menu>
          {!editResultPermission && !deleteResultPermission ? null : (
            <MenuButton>
              <BsThreeDotsVertical />
            </MenuButton>
          )}

          <MenuList>
            {editResultPermission ? (
              <MenuItem onClick={() => handleEdit(item)}>Edit </MenuItem>
            ) : null}
            {deleteResultPermission ? (
              <MenuItem onClick={() => handleDelete(item)}> Delete </MenuItem>
            ) : null}
          </MenuList>
        </Menu>
      ),
    }),
  };

  return (
    <Box bg={"#FFFFFF"} py={"30px"} minH={"100vh"} px={3}>
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={"20px"}
        borderBottom={"1px solid #B4B1B1"}
        pb={2}
        mb={5}
      >
        <Text fontSize={"18px"} fontWeight={"700"} color={"#D11A2A"}>
          {" "}
          Upload Exam Result
        </Text>
        <Flex
          flexDirection={["column", "column", "row"]}
          alignItems={"center"}
          gap={[3, null, null]}
        >
          <Text
            cursor={"pointer"}
            color={"#D11A2A"}
            fontSize={"12px"}
            fontWeight={"600"}
            lineHeight={"16px"}
            textDecoration={"underline"}
            onClick={handleDownloadTemplate}
          >
            DOWNLOAD TEMPLATE HERE
          </Text>
          {addResultPermission ? <Create {...createData} /> : null}
          <SortBy />

          <Button
            leftIcon={<PiExport style={{ width: "15px", height: "15px" }} />}
            bg={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
            _active={{ backgroundColor: "transparent" }}
            color={"#404040"}
            fontFamily={"inter"}
            fontSize={"12px"}
            fontWeight={"500"}
            lineHeight={"24px"}
            letterSpacing={"0.4px"}
          >
            Export
          </Button>
        </Flex>
      </Flex>
      {loading ? (
        <Flex justifyContent={"center"} alignItems={"center"}>
          <DotLoader />
        </Flex>
      ) : (
        <>
          {error ? (
            <Alert status="error" className="mt-3" mb={3}>
              <AlertIcon />
              <AlertTitle>{errorsArray.join(", ")}</AlertTitle>
            </Alert>
          ) : (
            <CustomTablePage2 {...tableProps} />
          )}
        </>
      )}
    </Box>
  );
};

export default ExamResult;
