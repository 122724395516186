import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
  loading: false,
  error: null,
  announcements: [],
  editAnnouncement: "",
  announcementsParams:{
    page: 1,
    pageSize: 5,
  },
};

export const fetchAnnouncement = createAsyncThunk(
  "announcement/fetchAnnouncement",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/Announcement${objToParams(getState()?.announcement?.announcementsParams)}`);
  }
);

export const createAnnouncement = createAsyncThunk(
  "announcement/createAnnouncement",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Announcement`,
        "post",
        arg,
        null,
        "multipart/form-data",
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    setEditAnnouncement: (state, { payload }) => {
      state.editAnnouncement = payload;
    },
    setUpdateAnnouncementsPagination:(state, {payload}) => {
      state.announcementsParams.page = payload;
      state.announcements = [];
    },
    setUpdateAnnouncementsPageSize:(state, {payload}) => {
      state.announcementsParams.pageSize = payload;
      state.announcements = [];
    },

  },
  extraReducers: (builder) => {
    // get announcement:
    builder.addCase(fetchAnnouncement.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAnnouncement.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.announcements = payload;
      state.error = null;
    });

    builder.addCase(fetchAnnouncement.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create announcement:
    builder.addCase(createAnnouncement.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createAnnouncement.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createAnnouncement.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const {setEditAnnouncement, setUpdateAnnouncementsPagination, setUpdateAnnouncementsPageSize} = announcementSlice.actions;
export default announcementSlice.reducer;