import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
  loading: false,
  error: null,
  approvalSettings: [],
  editApprovaSettings: "",
  approvalSettingsParams:{
    page: 1,
    pageSize: 5,
  },
};

export const fetchApprovalSettings = createAsyncThunk(
  "approvalSettings/fetchApprovalSettings",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/ApprovalSetting${objToParams(getState()?.approvalSettings?.approvalSettingsParams)}`);
  }
);

export const createApprovalSettings = createAsyncThunk(
  "approvalSettings/createApprovalSettings",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/ApprovalSetting`,
        "post",
        arg,
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


const approvalSettingsSlice = createSlice({
  name: "approvalSettings",
  initialState,
  reducers: {
    setEditApprovaSettings: (state, { payload }) => {
      state.editApprovaSettings = payload;
    },

    setUpdateApprovalSettingsPagination:(state, {payload}) => {
      state.approvalSettingsParams.page = payload;
      state.approvalSettings = [];
    },
    setUpdateApprovalSettingsPageSize:(state, {payload}) => {
      state.approvalSettingsParams.pageSize = payload;
      state.approvalSettings = [];
    },

  },
  extraReducers: (builder) => {
    // get approval settings:
    builder.addCase(fetchApprovalSettings.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchApprovalSettings.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.approvalSettings = payload;
      state.error = null;
    });

    builder.addCase(fetchApprovalSettings.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create approval settings:
    builder.addCase(createApprovalSettings.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createApprovalSettings.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createApprovalSettings.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const { setEditApprovaSettings, setUpdateApprovalSettingsPagination, setUpdateApprovalSettingsPageSize } = approvalSettingsSlice.actions;
export default approvalSettingsSlice.reducer;