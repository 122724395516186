import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
    loading: false,
    error: null,
    students: [],
    studentDetails:"",
    feePaymentDetail:"",
    feepaymentId:"",
    paymentConfirmation: false,
    studentsParams:{
      status: "Pending",
      page: 1,
      pageSize: 5,
    },
};

export const fetchStudentApplicationApproval = createAsyncThunk(
  "studentApplicationApproval/fetchStudentApplicationApproval",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/Application/students${objToParams(getState()?.studentApplicationApproval?.studentsParams)}`,);
  }
);

export const fetchStudentDetails = createAsyncThunk(
  "studentApplicationApproval/fetchStudentDetails",
  async (id) => {
    return httpRequest(`${BASE_URL}/v2/Application/${id}/student-details`,);
  }
);

export const approveStudentApplication = createAsyncThunk(
  "studentApplicationApproval/approveStudentApplication",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Application/approve-student`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const studentApplicationApprovalSlice = createSlice({
  name: "studentApplicationApproval",
  initialState,
  reducers: {
    setStudentDetails: (state, { payload }) => {
      state.studentDetails = payload;
    },
    setFeePaymentDetail: (state, { payload }) => {
      state.feePaymentDetail = payload;
    },
    setFeepaymentId: (state, { payload }) => {
      state.feepaymentId = payload;
    },
    setPaymentConfirmation: (state, { payload }) => {
      state.paymentConfirmation = payload;
    },
    setUpdateStudentsStatus:(state, {payload}) => {
      state.studentsParams.status = payload;
      state.students = [];
    },
    setUpdateStudentsPagination:(state, {payload}) => {
      state.studentsParams.page = payload;
      state.students = [];
    },
    setUpdateStudentsPageSize:(state, {payload}) => {
      state.studentsParams.pageSize = payload;
      state.students = [];
    },
  },
  extraReducers: (builder) => {

    //  get student applications:
    builder.addCase(fetchStudentApplicationApproval.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(fetchStudentApplicationApproval.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.students = payload;
        state.error = null;
    });
    builder.addCase(fetchStudentApplicationApproval.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
    });

    //  get student details:
    builder.addCase(fetchStudentDetails.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(fetchStudentDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.studentDetails = payload;
        state.error = null;
    });
    builder.addCase(fetchStudentDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
    });

    //  approve student application:
    builder.addCase(approveStudentApplication.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(approveStudentApplication.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
    });
    builder.addCase(approveStudentApplication.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
    });

  }

});

export const { setStudentDetails, setFeePaymentDetail, setFeepaymentId, setPaymentConfirmation, setUpdateStudentsStatus, setUpdateStudentsPagination, setUpdateStudentsPageSize } = studentApplicationApprovalSlice.actions;
export default studentApplicationApprovalSlice.reducer;