import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import DotLoader from "./loaders/DotLoader";
import PaginationMain from "./layout/PaginationMain";

const CustomTablePage2 = ({
    sn = true, handlePagination, pageSize, handlePageSize,totalRecord,itemsPerPage,
    data, nextPage, previousPage,
    dataKeys,
    transformRow,
    theadBg,
    pageNumber,
    pageCount,
    title,
    isloading,
    variant = true,
    onClickRowAction,
    showPagination = true
  }) => {
    let initialCount = 0;

    const paginationMainData = {
      currentPage:pageNumber,
      totalRecord,
      itemsPerPage,
      nextPage,
      previousPage,
      pageSize,
      handlePagination,
      handlePageSize
    };

    
  return (
    <>
    <TableContainer className="text-xs font-light"
      sx={{
        overflowY: "scroll",
        scrollbarWidth: "none", 
        msOverflowStyle: "none", 
        "-ms-overflow-style": "none", 
        "&::-webkit-scrollbar": {
            display: "none", 
        },
        }}
        overflowY={"auto"} 
    >
      <Table>
        <Thead background={theadBg ? theadBg : "#D7343914"} width={"100%"}>
          <Tr color="#515151">
            {sn && (
              <Th style={{ maxWidth: 70, paddingLeft: 15 }} color="#515151">
                s/n
              </Th>
            )}
            {dataKeys && dataKeys?.length > 0  &&
              dataKeys?.map((item, titleKey) => (
                <Th key={titleKey} color="#515151">
                  {title[item]}
                </Th>
              ))}
          </Tr>
        </Thead>
        <Tbody>
          {data?.length >= 1 ? (
            data?.map((row, rowIndex) => {
              const rowObj = transformRow ? transformRow(row) : row;
              initialCount++;
              return (
                <React.Fragment key={initialCount}>
                  <Tr
                    onClick={onClickRowAction}
                    cursor={onClickRowAction && "pointer"}
                    // _hover={{ backgroundColor: "#F4FFFB" }}
                    bg={variant && rowIndex % 2 === 1 ? "#F9F8F8" : ""}
                  >
                    {sn && (
                      <Td
                        style={{ maxWidth: 70, paddingLeft: 15 }}
                        color={"#515151"}
                        lineHeight={"20px"}
                      >
                        <b>{initialCount}.</b>
                      </Td>
                    )}
                    {Object.keys(rowObj).map((key, columnIndex) => {
                      const cellData = rowObj[key];
                      return (
                        <Td key={`${rowIndex}-${columnIndex}`}>
                          {Array.isArray(cellData)
                            ? cellData.map((item, itemIndex) => (
                                <div
                                  key={`${rowIndex}-${columnIndex}-${itemIndex}`}
                                >
                                  {item}
                                </div>
                              ))
                            : cellData}
                        </Td>
                      );
                    })}
                  </Tr>
                </React.Fragment>
              );
            })
          ) : isloading? (
            <Tr>
              <Td
                style={{textAlign: "center" }}
                colSpan={
                    sn
                    ? Object.keys(dataKeys)?.length + 1
                    : Object.keys(dataKeys)?.length
                }
              >
                <div className="text-center" style={{ width: "100%" }}>
                  <DotLoader />
                </div>
              </Td>
            </Tr>
          ) : (
            <Tr>
              <Td
                style={{ color: "red", textAlign: "center" }}
                colSpan={sn ? dataKeys?.length + 1 : dataKeys?.length}
              >
                No data found.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
    {(data && data?.length > 0) && showPagination  ? (
        <PaginationMain {...paginationMainData} />
    ) : null}

  </>
  )
}

export default CustomTablePage2;