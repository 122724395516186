import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex,Text,Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import SortBy from '../components/SortBy';
import DownloadBtn from '../components/DownloadBtn';
import NYSCPendingTable from '../components/NYSCPendingTable';
import NYSCApprovedTable from '../components/NYSCApprovedTable';
import NYSCDeclinedTable from '../components/NYSCDeclinedTable';
// import NYSCInProgressTable from '../components/NYSCInProgressTable';
import { useDispatch, useSelector } from "react-redux";
import {fetchNYSCApplicationApproval, setUpdateNyscsStatus, setUpdateNyscsPagination, setUpdateNyscsPageSize} from "../redux/slices/NYSCApplicationApprovalSlice";

const NYSC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const NYSCApplication = useSelector((state) => state?.NYSCApplicationApproval);
  console.log("NYSCApplication", NYSCApplication);

  const getAllNYSCApplication = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchNYSCApplicationApproval());
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get NYSC Applications");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get NYSC Applications, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    getAllNYSCApplication();
  }, [getAllNYSCApplication, NYSCApplication?.nyscsParams]);

  const handleStatusChange = (status) =>{
    dispatch(setUpdateNyscsStatus(status));
  }

  const handlePagination = (page) =>{
    dispatch(setUpdateNyscsPagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateNyscsPageSize(size));
  };

  const nyscAppData = {
    handlePagination,
    handlePageSize,
    itemsPerPage:NYSCApplication?.nyscs?.currentPageRecord,
    totalRecord: NYSCApplication?.nyscs?.totalRecord,
    pageNumber: NYSCApplication?.nyscsParams?.page,
    nextPage: NYSCApplication?.nyscs?.nextPage,
    previousPage: NYSCApplication?.nyscs?.previousPage,
    pageSize: NYSCApplication?.nyscsParams?.pageSize,
  }

  return (
    <Box  pt={2} pb={5} minH={'100vh'} px={3}>
    <Flex bg={'#FFFFFF'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} py={2} px={3} mb={5}>
      <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}>NYSC Application</Text>
      <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
        <SortBy />
        <DownloadBtn />
      </Flex>
    </Flex>
    <Tabs variant='unstyled'>
      <TabList gap={'20px'} flexDirection={['column', 'row', 'row']}  pb={4}  
             justifyContent={['center', 'flex-start', 'flex-start']} alignItems={'center'}>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
           bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
          onClick={() =>  handleStatusChange("Pending") }
        >Pending</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        onClick={() => handleStatusChange("Approved") }
        >Approved</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        onClick={() => handleStatusChange("Declined") }
        >Declined</Tab>
        {/* <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        >In-progress</Tab> */}
      </TabList>

      <TabPanels bg={'#FFFFFF'} >
        <TabPanel>
          <NYSCPendingTable NYSCApplication={NYSCApplication?.nyscs} loading={loading} errorsArray={errorsArray} error={error} {...nyscAppData} />
        </TabPanel>
        <TabPanel>
          <NYSCApprovedTable NYSCApplication={NYSCApplication?.nyscs} loading={loading} errorsArray={errorsArray} error={error} {...nyscAppData} />
        </TabPanel>
        <TabPanel>
          <NYSCDeclinedTable NYSCApplication={NYSCApplication?.nyscs} loading={loading} errorsArray={errorsArray} error={error} {...nyscAppData} />
        </TabPanel>
        {/* <TabPanel>
          <StudenInProgressTable />
        </TabPanel> */}
      </TabPanels>
    </Tabs>
    
  </Box>
  )
}

export default NYSC;