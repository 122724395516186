import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
  loading: false,
  error: null,
  examTimeTable: [],
  editExamTimeTable: "",
  examTimeTableDetails: "",
  examTimeTableParams:{
    page: 1,
    pageSize: 5,
  },
};

export const fetchExamTimeTable = createAsyncThunk(
  "examTimeTable/fetchExamTimeTable",
  async (arg, {getState}) => {
    return httpRequest(`${BASE_URL}/v2/TimeTable${objToParams(getState()?.examTimeTable?.examTimeTableParams)}`);
  }
);

export const createExamTimeTable = createAsyncThunk(
  "examTimeTable/createExamTimeTable",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/TimeTable`,
        "post",
        arg,
        null,
        "multipart/form-data",
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


const examTimeTableSlice = createSlice({
  name: "examTimeTable",
  initialState,
  reducers: {
    setEditExamTimeTable: (state, { payload }) => {
      state.editExamTimeTable = payload;
    },
    setExamTimeTableDetails: (state, { payload }) => {
      state.examTimeTableDetails = payload;
    },

    setUpdateExamTimeTablePagination:(state, {payload}) => {
      state.examTimeTableParams.page = payload;
      state.examTimeTable = [];
    },
    setUpdateExamTimeTablePageSize:(state, {payload}) => {
      state.examTimeTableParams.pageSize = payload;
      state.examTimeTable = [];
    },

  },
  extraReducers: (builder) => {
    // get examTimeTable:
    builder.addCase(fetchExamTimeTable.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExamTimeTable.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.examTimeTable = payload;
      state.error = null;
    });

    builder.addCase(fetchExamTimeTable.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create examTimeTable:
    builder.addCase(createExamTimeTable.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createExamTimeTable.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createExamTimeTable.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const { setEditExamTimeTable, setExamTimeTableDetails, setUpdateExamTimeTablePagination, setUpdateExamTimeTablePageSize } = examTimeTableSlice.actions;
export default examTimeTableSlice.reducer;