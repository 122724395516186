import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { approvalReportSchema } from '../utils/formValidationSchema';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import MembershipApproval from '../components/MembershipApproval';
import { fetchApprovalReport, setUpdateApprovalReportPagination, setUpdateApprovalReportPageSize } from "../redux/slices/report2Slice";
import { setloadTost } from "../redux/slices/toastSlice";

const ApprovalReport = () => {
  const dispatch = useDispatch();
  const approvalReport = useSelector((state) => state.report2);

  // State to store the most recent form submission values
  const [formValues, setFormValues] = useState({
    startDate: '',
    endDate: '',
    category: '',
  });

  const initialValues = {
    category: '',
    startDate: '',
    endDate: '',
  };

  const handlePagination = (page) => {
    dispatch(setUpdateApprovalReportPagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateApprovalReportPageSize(size));
  };

  const approvalReportData = {
    handlePagination,
    handlePageSize,
    itemsPerPage: approvalReport?.approvalReport?.currentPageRecord,
    totalRecord: approvalReport?.approvalReport?.totalRecord,
    pageNumber: approvalReport?.reportsParams?.page,
    nextPage: approvalReport?.approvalReport?.nextPage,
    previousPage: approvalReport?.approvalReport?.previousPage,
    pageSize: approvalReport?.reportsParams?.pageSize,
  };

  const handleSubmit = async (values) => {
    setFormValues(values); // Save form values to state
    const response = await dispatch(
      fetchApprovalReport({
        startDate: values?.startDate,
        endDate: values?.endDate,
        approvalReportCategory: values?.category,
      })
    );

    if (response?.payload?.isSuccess === false) {
      dispatch(
        setloadTost({
          title: 'Error',
          description:
            response?.payload?.errors?.map((errors) => errors) ||
            response?.payload?.error?.description ||
            "Failed to get report",
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
      );
    } else {
      dispatch(
        setloadTost({
          title: 'Success',
          description: `Report generated successfully`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
      );
    }
  };

  // Auto-fetch report when reportsParams changes (e.g., pagination changes)
  useEffect(() => {
    const { startDate, endDate, category } = formValues;
    if (startDate && endDate && category) {
      dispatch(
        fetchApprovalReport({
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          approvalReportCategory: formValues.category,
        })
      );
    }
  }, [approvalReport?.reportsParams, dispatch, formValues]);

  return (
    <>
      <Box bgColor={'white'} height={["500px", '', '270px']} px={'30px'} py={'20px'} mb={5}>
        <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={approvalReportSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                <FormControl mt={4} isInvalid={errors.category && touched.category} color="#121212">
                  <FormLabel htmlFor='category' fontSize={'16px'}>Category</FormLabel>
                  <Select
                    placeholder='Select Category'
                    height={'52px'}
                    name="category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.category}
                  >
                    <option value='NYSCApproval'>NYSC Approval</option>
                    <option value='StudentApproval'>Student Approval</option>
                  </Select>
                  {touched.category && errors.category && (
                    <FormErrorMessage>{errors.category}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.startDate && touched.startDate} color="#121212">
                  <FormLabel htmlFor='startDate' fontSize={"16px"}>Start Date</FormLabel>
                  <Input
                    height={'52px'}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="startDate"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.startDate}
                  />
                  {touched.startDate && errors.startDate && (
                    <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.endDate && touched.endDate} color="#121212">
                  <FormLabel htmlFor='endDate' fontSize={"16px"}>End Date</FormLabel>
                  <Input
                    height={'52px'}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="endDate"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.endDate}
                  />
                  {touched.endDate && errors.endDate && (
                    <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex justifyContent={'flex-end'} alignItems={'center'}>
                <Button
                  mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  isLoading={approvalReport?.loading}
                  loadingText="fetching..."
                >
                  Fetch Report
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      {approvalReport?.approvalReport?.data ? (
        <MembershipApproval approvalReport={approvalReport?.approvalReport?.data} {...approvalReportData} />
      ) : null}
    </>
  );
};

export default ApprovalReport;
