import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import {
  Box,
  Flex,
  Text,
  Avatar,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Divider,
  Checkbox,
  Select,
  Button,
} from "@chakra-ui/react";
import CustomTable from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BiLeftArrowAlt } from "react-icons/bi";
import {
  fetchExamApplicationForm,
  AddExams,
} from "../redux/slices/examMgtSlice";
import { setloadTost } from "../redux/slices/toastSlice";
import { setFeePaymentDetail } from "../redux/slices/studentApplicationApprovalSlice";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { setFormModal } from "../redux/slices/modalSlice";

const ExamAppCentre = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  console.log("selectedItems", selectedItems);

  const examApplicationForm = useSelector(
    (state) => state?.examMgt?.examApplicationForm
  );
  console.log("examApplicationForm:", examApplicationForm);
  const examApplicationerror = useSelector((state) => state?.examMgt?.error);
  console.log("examApplication:", examApplicationerror);

  const newExamSitting = examApplicationForm?.examApplicationSubjects?.filter(
    (item) => item?.examSitting === "New"
  );

  const resitExamSitting = examApplicationForm?.examApplicationSubjects?.filter(
    (item) => item?.examSitting === "Resit"
  );
  // console.log('resitExamSitting', resitExamSitting)

  useEffect(() => {
    dispatch(fetchExamApplicationForm());
  }, [dispatch]);

  useEffect(() => {
    if (examApplicationerror) {
      dispatch(
        setloadTost({
          title: "error",
          description: `${examApplicationerror}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        })
      );
    }
  }, [dispatch, examApplicationerror]);

  const initialValues = {
    studentNumber: examApplicationForm?.studentNumber || "",
    fullName: examApplicationForm?.fullName || "",
    currentExamStage: examApplicationForm?.examCategory?.id || "",
    exemptionType: examApplicationForm?.exemptionType?.exemptionType || "",
    membershipCategory: examApplicationForm?.membershipCategory || "",
    phoneNumber: examApplicationForm?.phoneNumber || "",
    emailAddress: examApplicationForm?.email || "",
    examCenter: "",
  };

  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };
  const handleSubmit = async (values) => {
    if (selectedItems.length > 0) {
      const subjects = selectedItems?.map((item) => ({
        ...item,
        examSubjectId: item?.id,
        examSitting: item?.examSitting === "New" ? 0 : 1,
      }));
      const formData = {
        examPeriodId: examApplicationForm?.examPeriod?.id,
        examCenterId: values?.examCenter,
        examCategoryId: values?.currentExamStage,
        exemptionType: Number(values?.exemptionType),
        examApplicationSubjectModels: subjects,
      };
      const response = await dispatch(AddExams(formData));
      console.log("response", response);
      console.log(
        "feePaymentHistoryId",
        response?.payload?.feePaymentHistoryId
      );
      if (response?.payload?.isSuccess === true) {
        dispatch(fetchExamApplicationForm());
        dispatch(
          setloadTost({
            title: "Successs",
            description: `Exam Applied successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          })
        );
        const res = await httpRequest(
          `${BASE_URL}/v2/Payment/${response?.payload?.feePaymentHistoryId}/generate-remita`,
          "post"
        );
        console.log("res", res);
        if (res?.isSuccess === true) {
          dispatch(setFeePaymentDetail(res));
          dispatch(
            setFormModal({
              status: true,
              title: "Payment Details",
              type: "regPaymentDetails",
            })
          );
        } else {
          dispatch(
            setloadTost({
              title: "Error",
              description: `${res?.error?.description}`,
              status: "warning",
              duration: 5000,
              isClosable: true,
              position: "top",
            })
          );
        }
      } else {
        dispatch(
          setloadTost({
            title: "Failed",
            description: `${
              response?.payload?.errors?.map((errors) => errors) ||
              response?.payload?.error?.description ||
              "Failed to add exam application"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          })
        );
      }
    } else {
      dispatch(
        setloadTost({
          title: "Empty Field",
          description: `Please choose at least one subject`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        })
      );
    }
  };
  const tableProps = {
    data: newExamSitting,
    title: {
      selectSubject: "Select Subject",
      examSubject: "Exam Subject",
      examDate: "Exam Date",
      examFee: "Exam Fee",
    },
    dataKeys: ["selectSubject", "examSubject", "examDate", "examFee"],
    sn: false,
    showPagination: false,
    transformRow: (item) => ({
      selectSubject: item && (
        <Checkbox
          colorScheme="green"
          onChange={(e) => handleCheckboxChange(item, e.target.checked)}
        ></Checkbox>
      ),
      examSubject: <Box style={{ cursor: "pointer" }}>{item?.examSubject}</Box>,
      examDate: <Box style={{ cursor: "pointer" }}>{item?.examDate}</Box>,
      examFee: <Box style={{ cursor: "pointer" }}>{item?.examFee}</Box>,
    }),
  };

  const tableProps1 = {
    data: resitExamSitting,
    title: {
      selectSubject: "Select Subject",
      examSubject: "Exam Subject",
      examDate: "Exam Date",
      examFee: "Exam Fee",
    },
    dataKeys: ["selectSubject", "examSubject", "examDate", "examFee"],
    sn: false,
    showPagination: false,
    transformRow: (item) => ({
      selectSubject: item && (
        <Checkbox
          colorScheme="green"
          onChange={(e) => handleCheckboxChange(item, e.target.checked)}
        ></Checkbox>
      ),
      examSubject: <Box style={{ cursor: "pointer" }}>{item?.examSubject}</Box>,
      examDate: <Box style={{ cursor: "pointer" }}>{item?.examDate}</Box>,
      examFee: <Box style={{ cursor: "pointer" }}>{item?.examFee}</Box>,
    }),
  };

  return (
    <Box bg={"#FFFFFF"} py={"30px"} minH={"100vh"} px={5}>
      <Flex
        alignItems={"center"}
        gap={"20px"}
        borderBottom={"1px solid #B4B1B1"}
        pb={4}
        mb={5}
        cursor={"pointer"}
      >
        <Box
          bg={"#F9A36238"}
          rounded={"full"}
          p={1}
          onClick={() => navigate(-1)}
        >
          <BiLeftArrowAlt color="#D8991F" size={"20px"} />
        </Box>
        <Text
          fontSize={"18px"}
          fontWeight={"700"}
          color={"#222B45"}
          opacity={"0.5"}
        >
          Examination Application
        </Text>
      </Flex>
        <>
          <Flex
            gap={6}
            justifyContent={"centre"}
            alignItems={"center"}
            pb={7}
            mb={2}
          >
            <Avatar
              src=""
              size="xl"
              m={"auto"}
              border={"5px solid white"}
              shadow={"xl"}
              w={"105px"}
              h={"105px"}
            />
          </Flex>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            // validationSchema={}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched, values, handleBlur, handleChange }) => (
              <Form>
                <Flex
                  flexDirection={["column", "row", "row"]}
                  alignItems={"center"}
                  gap={[null, 3, 5]}
                >
                  <FormControl
                    mt={4}
                    isInvalid={errors.studentNumber && touched.studentNumber}
                    color="#121212"
                    fontFamily={"inter"}
                  >
                    <FormLabel htmlFor="studentNumber" fontSize={"16px"}>
                      Student Number
                    </FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={"#F3F3F3"}
                      name="studentNumber"
                      focusBorderColor="#8A8C8E"
                      borderRadius={"8px"}
                      h={"60px"}
                      borderColor={"#8A8C8E"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#B4B1B1" }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.studentNumber}
                      readOnly
                    />
                    {touched.studentNumber && errors.studentNumber && (
                      <FormErrorMessage>
                        {errors.studentNumber}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={errors.fullName && touched.fullName}
                    color="#121212"
                    fontFamily={"inter"}
                  >
                    <FormLabel htmlFor="fullName" fontSize={"16px"}>
                      Full Name
                    </FormLabel>
                    <Input
                      placeholder="Enter Here "
                      fontSize={"small"}
                      bg={"#F3F3F3"}
                      name="fullName"
                      focusBorderColor="#8A8C8E"
                      borderRadius={"8px"}
                      h={"60px"}
                      borderColor={"#8A8C8E"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#B4B1B1" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.fullName}
                      readOnly
                    />
                    {touched.fullName && errors.fullName && (
                      <FormErrorMessage>{errors.fullName}</FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Flex
                  flexDirection={["column", "row", "row"]}
                  alignItems={"center"}
                  gap={[null, 3, 5]}
                >
                  <FormControl
                    mt={4}
                    isInvalid={
                      errors.currentExamStage && touched.currentExamStage
                    }
                    color="#121212"
                    fontFamily={"inter"}
                  >
                    <FormLabel htmlFor="currentExamStage" fontSize={"16px"}>
                      {" "}
                      Current Exam Stage
                    </FormLabel>
                    <Select
                      name="currentExamStage"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bg={"#F3F3F3"}
                      focusBorderColor="#8A8C8E"
                      borderRadius={"8px"}
                      h={"60px"}
                      borderColor={"#8A8C8E"}
                      placeholder="Select Here"
                      _hover={{ borderColor: "#FFFFFF" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      value={values?.currentExamStage}
                    >
                      <option value={examApplicationForm?.examCategory?.id}>
                        {examApplicationForm?.examCategory?.name}
                      </option>
                    </Select>
                    {touched.currentExamStage && errors.currentExamStage && (
                      <FormErrorMessage>
                        {errors.currentExamStage}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={errors.exemptionType && touched.exemptionType}
                    color="#121212"
                    fontFamily={"inter"}
                  >
                    <FormLabel htmlFor="exemptionType" fontSize={"16px"}>
                      {" "}
                      Exemption Type
                    </FormLabel>
                    <Select
                      name="exemptionType"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bg={"#F3F3F3"}
                      focusBorderColor="#8A8C8E"
                      borderRadius={"8px"}
                      h={"60px"}
                      borderColor={"#8A8C8E"}
                      placeholder="Select Here"
                      _hover={{ borderColor: "#FFFFFF" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      value={values?.exemptionType}
                    >
                      <option
                        value={
                          examApplicationForm?.exemptionType?.exemptionType
                        }
                      >
                        {examApplicationForm?.exemptionType?.name}
                      </option>
                    </Select>
                    {touched.exemptionType && errors.exemptionType && (
                      <FormErrorMessage>
                        {errors.exemptionType}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Flex
                  flexDirection={["column", "row", "row"]}
                  alignItems={"center"}
                  gap={[null, 3, 5]}
                >
                  <FormControl
                    mt={4}
                    isInvalid={
                      errors.membershipCategory && touched.membershipCategory
                    }
                    color="#121212"
                    fontFamily={"inter"}
                  >
                    <FormLabel htmlFor="membershipCategory" fontSize={"16px"}>
                      {" "}
                      Membership Category
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={"#F3F3F3"}
                      name="membershipCategory"
                      focusBorderColor="#8A8C8E"
                      borderRadius={"8px"}
                      h={"60px"}
                      borderColor={"#8A8C8E"}
                      _hover={{ borderColor: "#B4B1B1" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.membershipCategory}
                      readOnly
                    />
                    {touched.membershipCategory &&
                      errors.membershipCategory && (
                        <FormErrorMessage>
                          {errors.membershipCategory}
                        </FormErrorMessage>
                      )}
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={errors.phoneNumber && touched.phoneNumber}
                    color="#121212"
                    fontFamily={"inter"}
                  >
                    <FormLabel htmlFor="phoneNumber" fontSize={"16px"}>
                      Phone Number
                    </FormLabel>
                    <Input
                      type="number"
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={"#F3F3F3"}
                      name="phoneNumber"
                      focusBorderColor="#8A8C8E"
                      borderRadius={"8px"}
                      h={"60px"}
                      borderColor={"#8A8C8E"}
                      _hover={{ borderColor: "#B4B1B1" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.phoneNumber}
                      readOnly
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                      <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={errors.emailAddress && touched.emailAddress}
                    color="#121212"
                    fontFamily={"inter"}
                  >
                    <FormLabel htmlFor="emailAddress" fontSize={"16px"}>
                      Email Address
                    </FormLabel>
                    <Input
                      placeholder="Enter Here "
                      fontSize={"small"}
                      bg={"#F3F3F3"}
                      name="emailAddress"
                      focusBorderColor="#8A8C8E"
                      borderRadius={"8px"}
                      h={"60px"}
                      borderColor={"#8A8C8E"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#B4B1B1" }}
                      type="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.emailAddress}
                      readOnly
                    />
                    {touched.emailAddress && errors.emailAddress && (
                      <FormErrorMessage>{errors.emailAddress}</FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Divider py={8} borderBottom={"1px solid #B4B1B1"} />
                <Text
                  color={"#121212"}
                  fontWeight={"700"}
                  fontSize={"20px"}
                  lineHeight={"21px"}
                  fontFamily={"roboto"}
                  mt={8}
                >
                  EXAMINATION SUBJECTS
                </Text>
                <Text
                  color={"#D11A2A"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  lineHeight={"16.41px"}
                  fontFamily={"roboto"}
                  mt={3}
                  mb={10}
                >
                  Please Note that exam results are carefully checked and
                  subjected to quality assurance reviews. Therefore the
                  institute will not enter into any correspondence on them.
                </Text>
                <CustomTable {...tableProps} />
                <Divider py={8} borderBottom={"1px solid #B4B1B1"} />
                <Text
                  color={"#121212"}
                  fontWeight={"700"}
                  fontSize={"20px"}
                  lineHeight={"21px"}
                  fontFamily={"roboto"}
                  mt={8}
                  mb={10}
                >
                  RESIT EXAMINATION SUBJECTS
                </Text>
                <CustomTable {...tableProps1} />
                <FormControl
                  mt={10}
                  isInvalid={errors.examCenter && touched.examCenter}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="examCenter" fontSize={"16px"}>
                    Exam Centre
                  </FormLabel>
                  <Select
                    name="examCenter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={"#F3F3F3"}
                    focusBorderColor="#8A8C8E"
                    borderRadius={"8px"}
                    h={"60px"}
                    borderColor={"#8A8C8E"}
                    placeholder="Select Here"
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.examCenter}
                  >
                    {examApplicationForm?.examCentres?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </Select>
                  {touched.examCenter && errors.examCenter && (
                    <FormErrorMessage>{errors.examCenter}</FormErrorMessage>
                  )}
                </FormControl>
                <Flex mt={10} gap={3} alignItems={"center"}>
                  <Button
                    px={10}
                    bgColor={"#8A8C8E"}
                    color={"#FFFFFF"}
                    fontWeight={"500"}
                    fontSize={"14px"}
                    lineHeight={"16px"}
                    borderRadius={"5px"}
                    _hover={{ bgColor: "" }}
                    _active={{ bgColor: "" }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    px={10}
                    bgColor={"#D11A2A"}
                    color={"#FFFFFF"}
                    fontWeight={"500"}
                    fontSize={"14px"}
                    lineHeight={"16px"}
                    borderRadius={"5px"}
                    _hover={{ bgColor: "" }}
                    _active={{ bgColor: "" }}
                  >
                    Submit and proceed with payment
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </>
    </Box>
  );
};

export default ExamAppCentre;
