import React from 'react'
import { Box, Flex, Text, Select } from '@chakra-ui/react';
import { MdOutlineFileDownload } from "react-icons/md";
import CustomTablePage2 from './CustomTablePage2';

const MembershipApproval = ({approvalReport, handlePagination ,handlePageSize ,itemsPerPage,totalRecord,pageNumber,nextPage, previousPage, pageSize}) => {
  const tableProps = {
    data: approvalReport,
    title: {
      memberName: "Member Name",
      memberCategory: "Member Category",
      approvedStage: "Approved Stage",
      memberStatus: "Status",
    },
    dataKeys: [
      "memberName",
      "memberCategory",
      "approvedStage",
      "memberStatus",
    ],
    handlePagination,
    handlePageSize,
    itemsPerPage,
    totalRecord,
    pageNumber,
    nextPage,
    previousPage,
    pageSize,
    transformRow: (item) => ({
      memberName: item.memberName,
      memberCategory: item.category,
      approvedStage: item.approvalStage,
      memberStatus: <Box style={{ cursor: 'pointer' }} color={getStatusColor(item?.approvalStatus)}>{item.approvalStatus}</Box>,
    }),
  };


  const getStatusColor = (memberStatus) => {
    switch (memberStatus) {
      case 'Approved':
        return '#0ADB13';
      case 'Pending':
        return '#FFC34F';
      case 'Declined':
        return '#D73439'
      default:
        return '';
    }
  };

  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'rgba(209, 26, 42, 0.5)'} >Approval Report</Text>
        <Flex alignItems={'center'} justifyContent={'center'} flexDirection={["column", "column", "row"]} gap={[3, null, null]} >
          <Select placeholder='Sort by' onFocusBorderColor=''>
            <option value='option1'>Option 1</option>
            <option value='option2'>Option 2</option>
            <option value='option3'>Option 3</option>
          </Select>
          <Flex alignItems={'center'} _hover={{ color: '#D11A2A' }} cursor={'pointer'}>
            <Text fontSize={'14px'} fontWeight={500} color={'#404040'} w={'100px'} _hover={{ color: '#D11A2A' }}>CSV download</Text>
            <MdOutlineFileDownload />
          </Flex>
        </Flex>
      </Flex>
      <CustomTablePage2 {...tableProps} />
    </Box>
  )
}

export default MembershipApproval;