import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex,Text,Tabs, TabList, TabPanels, Tab, TabPanel} from '@chakra-ui/react';
import SortBy from '../components/SortBy';
import DownloadBtn from '../components/DownloadBtn';
import StudentPendingTable from '../components/StudentPendingTable';
import StudentApprovedTable from '../components/StudentApprovedTable';
import StudentDeclinedTable from '../components/StudentDeclinedTable';
// import StudenInProgressTable from '../components/StudenInProgressTable';
import { useDispatch, useSelector } from "react-redux";
import {fetchStudentApplicationApproval, setUpdateStudentsStatus, setUpdateStudentsPagination, setUpdateStudentsPageSize} from "../redux/slices/studentApplicationApprovalSlice";

const Student = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const studentApplication = useSelector((state) => state?.studentApplicationApproval);
  console.log('studentApplication', studentApplication)

  const getAllStudentApplication = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchStudentApplicationApproval());
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Student Applications");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Student Applications, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    getAllStudentApplication();
  }, [getAllStudentApplication, studentApplication?.studentsParams]);

  const handleStatusChange = (status) =>{
    dispatch(setUpdateStudentsStatus(status));
  }

  const handlePagination = (page) =>{
    dispatch(setUpdateStudentsPagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateStudentsPageSize(size));
  };

  const studentAppData = {
    handlePagination,
    handlePageSize,
    itemsPerPage:studentApplication?.students?.currentPageRecord,
    totalRecord: studentApplication?.students?.totalRecord,
    pageNumber: studentApplication?.studentsParams?.page,
    nextPage: studentApplication?.students?.nextPage,
    previousPage: studentApplication?.students?.previousPage,
    pageSize: studentApplication?.studentsParams?.pageSize,
  }

  return (
    <Box  pt={2} pb={5} minH={'100vh'} px={3}>
    <Flex bg={'#FFFFFF'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} py={2} px={3} mb={5}>
      <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}>Student Application</Text>
      <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
        <SortBy />
        <DownloadBtn />
      </Flex>
    </Flex>
    <Tabs variant='unstyled'>
      <TabList gap={'20px'} flexDirection={['column', 'row', 'row']}  pb={4}  
             justifyContent={['center', 'flex-start', 'flex-start']} alignItems={'center'}>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
           bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
          onClick={() =>  handleStatusChange("Pending") }
        >Pending</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        onClick={() => handleStatusChange("Approved") }
        >Approved</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        onClick={() => handleStatusChange("Declined") }
        >Declined</Tab>
        {/* <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        >In-progress</Tab> */}
      </TabList>

      <TabPanels bg={'#FFFFFF'} >
        <TabPanel>
          <StudentPendingTable studentApplication={studentApplication?.students} loading={loading} errorsArray={errorsArray} error={error} {...studentAppData} />
        </TabPanel>
        <TabPanel>
          <StudentApprovedTable studentApplication={studentApplication?.students} loading={loading} errorsArray={errorsArray} error={error} {...studentAppData} />
        </TabPanel>
        <TabPanel>
          <StudentDeclinedTable studentApplication={studentApplication?.students} loading={loading} errorsArray={errorsArray} error={error} {...studentAppData} />
        </TabPanel>
        {/* <TabPanel>
          <StudenInProgressTable />
        </TabPanel> */}
      </TabPanels>
    </Tabs>
    
  </Box>
  )
}

export default Student;