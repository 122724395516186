import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Create from '../components/layout/Create';
import { useDispatch, useSelector } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import { checkPermissions} from '../components/CheckPermission';
import SortBy from '../components/SortBy';
import {fetchApprovalSettings, setEditApprovaSettings, setUpdateApprovalSettingsPagination, setUpdateApprovalSettingsPageSize} from '../redux/slices/approvalSettingsSlice';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from '../components/loaders/DotLoader';
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";
import CustomTablePage2 from '../components/CustomTablePage2';


function ApprovalSettings() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const settingsData= useSelector(state => state?.approvalSettings);
    
    const getAllApprovalSettings = useCallback( async() => {
        setLoading(true);
        setError("");
        try {
          const response = await dispatch(fetchApprovalSettings());
          if (response?.payload?.name === "AxiosError"){
            setLoading(false);
            setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get approval settings, please check your request/network");
          }
          setLoading(false);
          
        } catch (error) {
          setError(error?.payload?.message || "Failed to get approval settings, please check your network");
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(clearsearchFilter());
        getAllApprovalSettings();
    }, [dispatch, getAllApprovalSettings, settingsData?.approvalSettingsParams]);

    const addApprovalSettingsPermission = checkPermissions( 'Approval', 'Approval Settings', 'Create', 'Granted' );
    const editApprovalSettingsPermission = checkPermissions( 'Approval', 'Approval Settings', 'Edit', 'Granted' );
    const deleteApprovalSettingsPermission = checkPermissions( 'Approval', 'Approval Settings', 'Delete', 'Granted' );

    const createData = {
        onClick: () => { dispatch(setFormModal({ status: true, title: "Set Approval Order", type: "SetApprovalOrderForm", })); },
        createText: "Set Approval Order",
    }

    const handlePagination = (page) =>{
        dispatch(setUpdateApprovalSettingsPagination(page));
      };
    
      const handlePageSize = (size) => {
        dispatch(setUpdateApprovalSettingsPageSize(size));
      };

    const handleEdit = (item) => {
        dispatch(setEditApprovaSettings(item));
        dispatch(setFormModal({status:true, title: "Edit Approval Order", type:"EditApprovalOrderForm"}));
    }

    const handleDelete = async (item) => {
        setError('');
       try {
          const response = await httpRequest(`${BASE_URL}/v2/ApprovalSetting/${item?.id}/delete`,
          'post', 
        );
        if(response?.isSuccess === true){
          setError('');
          dispatch(fetchApprovalSettings());
          dispatch(setloadTost({ title: 'Approval Settings Deleted', description: `${item?.name}  Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
       }else{
          setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to delete item");
        }
        
       } catch (error) {
        setError(error?.response?.message || "Failed to delete item");
       }
  
    }
    

    const tableProps = {
        data: settingsData?.approvalSettings?.data,
        title: {
            approvalName: "Approval Name",
            approvalOrder: "Approval Order",
            memebershipGrade: "Memebership Grade",
            approvalPoint: "Approval Point",
            dateCreated: "Date Created",
            Details: "",
        },
        dataKeys: [
            "approvalName",
            "approvalOrder",
            "memebershipGrade",
            "approvalPoint",
            "dateCreated",
            "Details",
        ],
        handlePagination,
        handlePageSize,
        itemsPerPage:settingsData?.approvalSettings?.currentPageRecord,
        totalRecord: settingsData?.approvalSettings?.totalRecord,
        pageNumber: settingsData?.approvalSettingsParams?.page,
        nextPage: settingsData?.approvalSettings?.nextPage,
        previousPage: settingsData?.approvalSettings?.previousPage,
        pageSize: settingsData?.approvalSettingsParams?.pageSize,
        transformRow: (item) => ({
            approvalName: <Box>{item?.name && item?.name}</Box>,
            approvalOrder: item?.requiredApprovalLevel && item?.requiredApprovalLevel,
            memebershipGrade: item?.membershipCategory && item?.membershipCategory,
            approvalPoint: item?.approvalPoint && item?.approvalPoint,
            dateCreated: item?.createdOn && format(new Date(item?.createdOn), 'yyyy-MM-dd'),
            Details: item &&  (
                <Menu px={'10px'}>
                    {
                        !editApprovalSettingsPermission && !deleteApprovalSettingsPermission ? null : (
                        <MenuButton>
                            <BsThreeDotsVertical />
                        </MenuButton>
                        )
                    }

                    <MenuList>
                        {
                        editApprovalSettingsPermission ? (
                            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
                        ) : null
                        }
                        {
                        deleteApprovalSettingsPermission ? (
                            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
                        ) : null
                        }
                    </MenuList>
                </Menu>
            ),
        }),
    };
    
    return (
        <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
            <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
                <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'}>Approval Settings</Text>
                <Flex justifyContent={'center'} alignItems={'center'} flexDirection={["column", "column", "row"]} gap={[3, null, null]} >
                    {
                    addApprovalSettingsPermission ? (
                        <Create {...createData} />
                        ) : null
                    }
                    <SortBy />
                </Flex>
            </Flex>
            {
            loading ? (
                <Flex justifyContent={'center'} alignItems={'center'}>
                    <DotLoader />
                </Flex>
            ):(
                <>
                {
                    error ? (
                    <Alert status="error" className="mt-3" mb={3}>
                        <AlertIcon />
                        <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                    </Alert>
                    ) 
                    : (<CustomTablePage2 {...tableProps} />)
                }
                </>
            )
            }
        </Box>
    )
}

export default ApprovalSettings