import React, { useState } from 'react'
import { setFormModal } from '../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Alert, AlertIcon, AlertTitle, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react';
import { BASE_URL } from '../config/settings';
import { fetchFees } from '../redux/slices/feeMgtSlice';
import httpRequest from '../utils/httpRequest';
import { setloadTost } from '../redux/slices/toastSlice';
import { addFeeSchema } from '../utils/formValidationSchema';

function EditFee() {
    
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const editFeeData = useSelector((state) => state?.feeMgt?.editFees);

    const initialValues = {
        feeName: editFeeData?.feeName || '',
        feeType: editFeeData?.feeType|| '',
        memberCategory: editFeeData?.memberCategory || '',
        feeFrequency: editFeeData?.frequency || '',
        amount: editFeeData?.amount || '',
    };

    // console.log(editFeeData)

    const feeTypeData = useSelector((state) => state?.feeMgt?.feeType?.data);

    const handleSubmit = async (values) => {
        setLoading(true);
        setError("");
        try {
            const formData = {
                feeName: values?.feeName,
                feeType: values?.feeType,
                frequency: values?.feeFrequency,
                memberCategory: values?.memberCategory,
                amount: values?.amount,
                isActive: true,
            };
            const response = await httpRequest(
                `${BASE_URL}/v2/Fee/${editFeeData?.id}/update`,
                "post",
                formData
            );
            // console.log(response);
            if (response.isSuccess === true) {
                setLoading(false);
                dispatch(fetchFees());
                dispatch(setFormModal({ status: false, data: null }));
                dispatch(setloadTost({ title: 'Fee Edited', description: `${values?.feeName} fee has been Edited successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
            } else {
                setLoading(false);
                setError(response?.payload?.errors?.map((error) => error) || response?.payload?.error?.description || "Failed to edit fee ");
            }
        } catch (error) {
            setError(error?.payload?.message || "Failed to edit fee, please check your network");
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            {
                error && (
                    <Alert status="error" className="mt-3" mb={3}>
                        <AlertIcon />
                        <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                    </Alert>
                )
            }
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={addFeeSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({
                    errors, touched, values, handleBlur, handleChange
                }) => {
                    if (values?.feeType === "Examination"){
                        setStatus(true);
                    }else{
                        setStatus(false);
                    }

                    return(
                    <Form>
                        <FormControl mt={4} isInvalid={errors.feeName && touched.feeName} color="#121212">
                            <FormLabel htmlFor='feeName' fontSize={'16px'}>Fee Name</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="feeName"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.feeName}
                            />
                            {touched.feeName && errors.feeName && (
                                <FormErrorMessage>{errors.feeName}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.feeType && touched.feeType} color="#121212">
                            <FormLabel htmlFor='feeType' fontSize={'16px'}>Fee Type</FormLabel>
                            <Select
                                name="feeType"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.feeType}
                            >
                                {
                                    feeTypeData.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))
                                }
                            </Select>
                            {touched.feeType && errors.feeType && (
                                <FormErrorMessage>{errors.feeType}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.feeFrequency && touched.feeFrequency} color="#121212">
                            <FormLabel htmlFor='feeFrequency' fontSize={'16px'}>Fee Frequency</FormLabel>
                            <Select
                                name="feeFrequency"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.feeFrequency}
                            >
                                <option value='Once'>Once</option>
                                <option value='Biennial'>Biennial</option>
                                <option value='Annual'>Annual</option>
                            </Select>
                            {touched.feeFrequency && errors.feeFrequency && (
                                <FormErrorMessage>{errors.feeFrequency}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.memberCategory && touched.memberCategory} color="#121212">
                            <FormLabel htmlFor='memberCategory' fontSize={'16px'}>Member Category</FormLabel>
                            <Select
                                name="memberCategory"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.memberCategory}
                            >
                                {
                                    status? (
                                        <option value="All"> All</option>
                                    ): (
                                        <>
                                            <option value='Student'>Student</option>
                                            <option value='NYSC'>NYSC</option>
                                            <option value='Graduate'>Graduate</option>
                                            <option value='Associate'>Associate</option>
                                            <option value='Fellow'>Fellow</option>
                                            <option value='Retiree'>Retiree</option>
                                        </>
                                    )
                                }
                            </Select>
                            {touched.memberCategory && errors.memberCategory && (
                                <FormErrorMessage>{errors.memberCategory}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.amount && touched.amount} color="#121212">
                            <FormLabel htmlFor='amount' fontSize={'16px'}>Amount</FormLabel>
                            <Input
                                placeholder="Enter amount"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="amount"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.amount}
                            />
                            {touched.amount && errors.amount && (
                                <FormErrorMessage>{errors.amount}</FormErrorMessage>
                            )}
                        </FormControl>
                        <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={"#D11A2A"}
                                _hover={{ background: " " }}
                                color={"#FFFFFF"}
                                type="submit"
                                isLoading={loading}
                                loadingText="Saving ..."
                            >
                                Edit Fee
                            </Button>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={""}
                                _hover={{ background: " " }}
                                color={"#8F9BB3"}
                                onClick={() => {
                                    dispatch(setFormModal({ status: false, data: null }));
                                }}
                                loadingText="Saving ..."
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </Form>
                )}}
            </Formik>
        </>
    )
}

export default EditFee