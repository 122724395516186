import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import SortBy from '../components/SortBy';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from '../components/loaders/DotLoader';
import Create from '../components/layout/Create';
import { Box, Flex, Text, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { BiLeftArrowAlt } from "react-icons/bi";
import { PiExport } from "react-icons/pi";
import {BsThreeDotsVertical} from 'react-icons/bs';
import {setFormModal} from "../redux/slices/modalSlice";
import {fetchApplicableExemption, deleteApplicableExemption, setUpdateApplicableExemptionPagination, setUpdateApplicableExemptionPageSize} from "../redux/slices/entryQualificationTypeSlice";
import { setloadTost } from '../redux/slices/toastSlice';
import CustomTablePage2 from '../components/CustomTablePage2';

const ApplicableExemption = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const examCategoryId = searchParams.get("examCategoryId");
    const applicableExemption = useSelector((state) => state?.entryQualificationType);

    useEffect(() => {
        dispatch(fetchApplicableExemption(id));
        dispatch(clearsearchFilter());
    }, [dispatch, id, applicableExemption?.entryQualificationTypeParams]);

    const createData = {
        onClick: () => { dispatch(setFormModal({status:true, title: "Add Applicable Exemption ", type:"AddExemption", data:{id, examCategoryId}})); },
        createText: "Add Exemption ",
    }

    const handlePagination = (page) =>{
      dispatch(setUpdateApplicableExemptionPagination(page));
    };
  
    const handlePageSize = (size) => {
      dispatch(setUpdateApplicableExemptionPageSize(size));
    };

    const handleDelete = async (item) => {
        const response = await dispatch(deleteApplicableExemption(item?.id));
        if (response?.payload?.isSuccess === true) {
            dispatch(fetchApplicableExemption(id));
            dispatch(setloadTost({ title: 'Successs', description: `Applicable Exemption Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
            dispatch(setloadTost({ title: 'Failed', description: `${response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to Delete Applicable Exemption"}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
        }
    }

    const tableProps = {
        data: applicableExemption?.applicableExemption?.data,
        title: {
          name: "Name",
          code:"Code",
          Action:'',
        },
        dataKeys: [
          "name",
          "code",
          "Action",
        ],
        handlePagination,
        handlePageSize,
        itemsPerPage:applicableExemption?.applicableExemption?.currentPageRecord,
        totalRecord: applicableExemption?.applicableExemption?.totalRecord,
        pageNumber: applicableExemption?.entryQualificationTypeParams?.page,
        nextPage: applicableExemption?.applicableExemption?.nextPage,
        previousPage: applicableExemption?.applicableExemption?.previousPage,
        pageSize: applicableExemption?.entryQualificationTypeParams?.pageSize,
          
        transformRow: (item) => ({
          name: <Box style={{cursor:'pointer'}}>{item?.name}</Box>,
          code: <Box style={{cursor:'pointer'}}>{item?.code}</Box>,
          Action: item && (
            <Menu>
                <MenuButton>
                <BsThreeDotsVertical />
                </MenuButton>
              <MenuList >
                <MenuItem onClick={() => handleDelete(item)}> Delete </MenuItem>
              </MenuList>
            </Menu>
          )
        }),
      };
    
  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={4} mb={5} cursor={'pointer'}>
          <Flex alignItems={'center'} gap={'20px'} onClick={() => navigate(-1)} >
              <Box bg={'#F9A36238'} rounded={'full'} p={1}>
                  <BiLeftArrowAlt color="#D8991F" size={'20px'} />
              </Box>
              <Text fontSize={'18px'} fontWeight={'700'} color={'#222B45'} opacity={'0.5'}>Applicable Exemption</Text>
          </Flex>
          <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
              
            <Create {...createData} />
               
              <SortBy />
              <Button leftIcon={<PiExport style={{width: "15px", height: "15px"}} />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
                fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
              >
                Export
              </Button>
          </Flex>
      </Flex>
      {
        applicableExemption?.loading ? (
          <Flex justifyContent={"center"} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ): (
          <CustomTablePage2 {...tableProps} />
        )
      }
    </Box>
  )
}

export default ApplicableExemption;