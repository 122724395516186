import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Text, Select ,  FormControl, FormErrorMessage, FormLabel, Input,} from '@chakra-ui/react';
import { MdOutlineFileDownload } from "react-icons/md";
import {setloadTost} from "../redux/slices/toastSlice";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import {fetchMemberUpgradeReport, setUpdateMemberUpgradeReportPagination, setUpdateMemberUpgradeReportPageSize} from "../redux/slices/report2Slice";
import {memberUpgradeReportSchema} from "../utils/formValidationSchema";
import CustomTablePage2 from './CustomTablePage2';

const MembershipUpgradeReport = () => {
  const dispatch = useDispatch();
  const memUpgradeReport  = useSelector((state) => state.report2);

  // State to store the most recent form submission values
  const [formValues, setFormValues] = useState({
    startDate: '',
    endDate: '',
  });

  const initialValues = {
    startDate: '',
    endDate: '',
  };

  const handlePagination = (page) => {
    dispatch(setUpdateMemberUpgradeReportPagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateMemberUpgradeReportPageSize(size));
  };

  const tableProps = {
    data: memUpgradeReport?.memberUpgradeReport?.data,
    title: {
      memberName: "Member Name",
      currentMembership: "Current Level",
      proposedUpgrade: "Proposed Upgrade",
      memberStatus: "Status",
    },
    dataKeys: [
      "memberName",
      "currentMembership",
      "proposedUpgrade",
      "memberStatus",
    ],
    handlePagination,
    handlePageSize,
    itemsPerPage: memUpgradeReport?.memberUpgradeReport?.currentPageRecord,
    totalRecord: memUpgradeReport?.memberUpgradeReport?.totalRecord,
    pageNumber: memUpgradeReport?.reportsParams?.page,
    nextPage: memUpgradeReport?.memberUpgradeReport?.nextPage,
    previousPage: memUpgradeReport?.memberUpgradeReport?.previousPage,
    pageSize: memUpgradeReport?.reportsParams?.pageSize,
    transformRow: (item) => ({
      memberName: item?.memberName,
      currentMembership: item?.currentLevel,
      proposedUpgrade: item?.proposedLevel,
      memberStatus: <Box style={{ cursor: 'pointer' }} color={getStatusColor(item?.approvalStatus)}>{item?.approvalStatus}</Box>,
    }),
  }

  const getStatusColor = (memberStatus) => {
    switch (memberStatus) {
      case 'Approved':
        return '#0ADB13';
      case 'Pending':
        return '#FFC34F';
      case 'Declined':
        return '#D73439'
      default:
        return '';
    }
  };

  const handleSubmit = async (values) => {
    setFormValues(values); // Save form values to state
    const response = await dispatch(fetchMemberUpgradeReport({startDate: values?.startDate, endDate: values?.endDate,}));
    if (response?.payload?.isSuccess === false){
     dispatch(setloadTost({ title: 'Error', description: response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to get report", status: 'warning', duration: 5000, isClosable: true, position: 'top' }));
    }else{
     dispatch(setloadTost({ title: 'Success', description: `report generated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
   }
  };

  // Auto-fetch report when reportsParams changes (e.g., pagination changes)
  useEffect(() => {
    const { startDate, endDate } = formValues;
    if (startDate && endDate ) {
      dispatch(
        fetchMemberUpgradeReport({
          startDate: formValues.startDate,
          endDate: formValues.endDate,
        })
      );
    }
  }, [memUpgradeReport?.reportsParams, dispatch, formValues]);

  return (
    <>
      <Box bgColor={'white'} height={["500px","",'270px']} px={'30px'} py={'20px'} mb={5}>
        <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={memberUpgradeReportSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            errors, touched, values, handleBlur, handleChange
          }) => (

            <Form>
              <Flex flexDirection={["column","column","row"]} alignItems={"center"} gap={3}>
                <FormControl mt={4} isInvalid={errors.startDate && touched.startDate}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='startDate' fontSize={"16px"}>Start Date</FormLabel>
                  <Input
                    height={'52px'}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="startDate"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.startDate}
                  />
                  {touched.startDate && errors.startDate && (
                    <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='endDate' fontSize={"16px"}>End Date</FormLabel>
                  <Input
                    height={'52px'}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="endDate"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.endDate}
                  />
                  {touched.endDate && errors.endDate && (
                    <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex justifyContent={'flex-end'} alignItems={'center'}>
                <Button
                  mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  isLoading={memUpgradeReport?.loading}
                  loadingText="fetching..."
                >
                  Fetch Report
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      {
        memUpgradeReport?.memberUpgradeReport?.data ? (
          <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
            <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
              <Text fontSize={'18px'} fontWeight={'700'} color={'rgba(209, 26, 42, 0.5)'} >Membership Upgrade Report</Text>
              <Flex alignItems={'center'} justifyContent={'center'} flexDirection={["column", "column", "row"]} gap={[3, null, null]} >
                <Select placeholder='Sort by' onFocusBorderColor=''>
                  <option value='option1'>Option 1</option>
                  <option value='option2'>Option 2</option>
                  <option value='option3'>Option 3</option>
                </Select>
                <Flex alignItems={'center'} _hover={{ color: '#D11A2A' }} cursor={'pointer'}>
                  <Text fontSize={'14px'} fontWeight={500} color={'#404040'} w={'100px'} _hover={{ color: '#D11A2A' }}>CSV download</Text>
                  <MdOutlineFileDownload />
                </Flex>
              </Flex>
            </Flex>
            <CustomTablePage2 {...tableProps} />
          </Box>
        ) :null
      }
    </>
  )
}

export default  MembershipUpgradeReport;