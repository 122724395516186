import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { AddApplicableExemptionSchema} from '../utils/formValidationSchema';
import {Button, Flex, FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react";
import { setFormModal } from "../redux/slices/modalSlice";
import { setloadTost } from "../redux/slices/toastSlice";
import { AddApplicableExemptions, fetchApplicableExemption } from "../redux/slices/entryQualificationTypeSlice";
import {fetchLookupExamSubject} from "../redux/slices/lookupSlice";

const AddApplicableExemption = ({ data }) => {
  const { id, examCategoryId } = data;
  const dispatch = useDispatch();
  const examSubjects = useSelector(
    (state) => state?.lookup?.lookupExamSubject
  );

  useEffect(() => {
    dispatch(fetchLookupExamSubject(examCategoryId));
  }, [dispatch, examCategoryId]);

  const initialValues = {
    examSubject: "",
  };

  const handleSubmit = async (values) => {
    const formData = {
        examSubjectId: values?.examSubject,
    }
    const response = await dispatch(AddApplicableExemptions({id:id, arg:formData}));
    if (response?.payload?.isSuccess === true) {
      dispatch(fetchApplicableExemption(id));
      dispatch(setFormModal({ status: false, data: null }));
      dispatch(setloadTost({ title: 'Successs', description: `Applicable exemption has been added successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
    }else{
        dispatch(setloadTost({ title: 'Failed', description: `${response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to add qualification type"}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
    }

  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={AddApplicableExemptionSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleChange,
        isSubmitting,
      }) => {
        return (
          <Form>
            <FormControl
              mt={4}
              isInvalid={errors.examSubject && touched.examSubject}
              color="#121212"
            >
              <FormLabel htmlFor="feeType" fontSize={"16px"}>
                Exam Subject
              </FormLabel>
              <Select
                name="examSubject"
                placeholder="Select Exam Subject"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"#F3F3F3"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.examSubject}
              >
                {examSubjects &&
                  examSubjects?.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.text}
                    </option>
                  ))}
              </Select>
              {touched.examSubject && errors.examSubject && (
                <FormErrorMessage>{errors.examSubject}</FormErrorMessage>
              )}
            </FormControl>

            <Flex justifyContent={"center"} alignItems={"center"} gap={5}>
              <Button
                mt={10}
                fontSize={"14px"}
                fontWeight={"500"}
                lineHeight={"16px"}
                width={"118px"}
                background={"#D11A2A"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                isLoading={isSubmitting}
                loadingText="Saving ..."
              >
                Save
              </Button>
              <Button
                mt={10}
                fontSize={"14px"}
                fontWeight={"500"}
                lineHeight={"16px"}
                width={"118px"}
                background={""}
                _hover={{ background: " " }}
                color={"#8F9BB3"}
                onClick={() => {
                  dispatch(setFormModal({ status: false, data: null }));
                }}
                loadingText="Saving ..."
              >
                Cancel
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddApplicableExemption;
