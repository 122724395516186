import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
    loading: false,
    error: null,
    examApplicationForm: [],
    examApplicationById: [],
    examApplications: [],
    examDocket: [],
    examResult: [],
    examYear: [],
    centerChange: [],   
    examCenterApplication: [],
    ExamCenterChangeDetails: null,
    examDetails:null,
    examCenterApplicationParams:{
        page: 1,
        pageSize: 5,
    },
};



export const fetchExamApplicationForm = createAsyncThunk(
    "examMgt/fetchExamApplicationForm",
    async () => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/exam-application-form?PageSize=1000`);
    }
);

export const fetchExamination = createAsyncThunk(
    "examMgt/fetchExamination",
    async (id) => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/${id}/exam-application`);
    }
);

export const getExamApplications = createAsyncThunk(
    "examMgt/getExamApplications",
    async () => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/exam-applications?PageSize=1000`);
    }
);

export const fecthExamDocket = createAsyncThunk(
    "examMgt/fecthExamDocket",
    async ( ExamPeriodId ) => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/exam-docket?ExamPeriodId=${ExamPeriodId}`);
    }
);

export const fecthExamResult = createAsyncThunk(
    "examMgt/fecthExamResult",
    async ({ ExamCategory, ExamPeriodId }) => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/exam-result?ExamCategory=${ExamCategory}&ExamPeriodId=${ExamPeriodId}`);
    }
);

export const fecthExamYear = createAsyncThunk(
    "examMgt/fecthExamYear",
    async () => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/exam-years`);
    }
);

export const AddExams = createAsyncThunk(
    "examMgt/AddExams",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/ExamManagement`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const fetchExamCenterChange = createAsyncThunk(
    "examMgt/fetchExamCenterChange",
    async () => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/centre-change`);
    }
);

export const AddCenterChange = createAsyncThunk(
    "examMgt/AddCenterChange",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/ExamManagement/centre-change`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);


export const AddApproveCenterChange = createAsyncThunk(
    "examMgt/AddApproveCenterChange",
    async ({ id, arg }, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/ExamManagement/centre-change/${id}/approve`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const fetchExamCenterChangeApplication = createAsyncThunk(
    "examMgt/fetchExamCenterChangeApplication",
    async (status, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/centre-change-applications/${status}${objToParams(getState()?.examMgt?.examCenterApplicationParams)}`);
    }
);

export const fetchExamCenterChangeDetails = createAsyncThunk(
    "examMgt/fetchExamCenterChangeDetails",
    async (id) => {
        return httpRequest(`${BASE_URL}/v2/ExamManagement/centre-change-application/${id}`);
    }
);



const examMgtSlice = createSlice({
    name: "examMgt",
    initialState,
    reducers: {
        setExamDetails: (state, {payload}) => {
            state.examDetails =payload ;
        },

        setUpdateExamCenterApplicationPagination:(state, {payload}) => {
            state.examCenterApplicationParams.page = payload;
            state.examCenterApplication = [];
          },
          setUpdateExamCenterApplicationPageSize:(state, {payload}) => {
            state.examCenterApplicationParams.pageSize = payload;
            state.examCenterApplication = [];
          },
    },

    extraReducers: (builder) => {
        // fetchExamApplicationForm
        builder.addCase(fetchExamApplicationForm.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchExamApplicationForm.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.examApplicationForm = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(fetchExamApplicationForm.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // fetchExamApplication
        builder.addCase(fetchExamination.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchExamination.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.examApplicationById = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(fetchExamination.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // getExamApplications
        builder.addCase(getExamApplications.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getExamApplications.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.examApplications = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(getExamApplications.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // fetchExamDocket
        builder.addCase(fecthExamDocket.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fecthExamDocket.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.examDocket = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(fecthExamDocket.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // fetchExamResult
        builder.addCase(fecthExamResult.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fecthExamResult.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.examResult = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(fecthExamResult.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // fetchExamYear
        builder.addCase(fecthExamYear.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fecthExamYear.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.examYear = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(fecthExamYear.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // addExams
        builder.addCase(AddExams.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(AddExams.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(AddExams.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // fetch exam center change
        builder.addCase(fetchExamCenterChange.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchExamCenterChange.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.centerChange = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(fetchExamCenterChange.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // add center change 
        builder.addCase(AddCenterChange.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(AddCenterChange.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(AddCenterChange.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });
        // fetch ExamCenter Change Application
        builder.addCase(fetchExamCenterChangeApplication.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchExamCenterChangeApplication.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.examCenterApplication = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(fetchExamCenterChangeApplication.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // fetchExamCenterChangeDetails
        builder.addCase(fetchExamCenterChangeDetails.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchExamCenterChangeDetails.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.ExamCenterChangeDetails = payload;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(fetchExamCenterChangeDetails.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // add approve center change 
        builder.addCase(AddApproveCenterChange.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(AddApproveCenterChange.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description || null;
        });

        builder.addCase(AddApproveCenterChange.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });
    }
});

export const {setExamDetails, setUpdateExamCenterApplicationPagination, setUpdateExamCenterApplicationPageSize} = examMgtSlice.actions;
export default examMgtSlice.reducer;