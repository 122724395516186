import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
    memberUpgradeApps: [],
    membershipUpgrade: [],
    memberUpgradeDetails: "",
    loading: false,
    error: null,
    memberUpgradeAppsParams:{
        status: "Pending",
        page: 1,
        pageSize: 5,
    },
};

export const fetchMembershipUpgrade = createAsyncThunk(
    "membershipUpgrade/fetchMembershipUpgrade",
    async () => {
        return httpRequest(`${BASE_URL}/v2/MembershipUpgrade?PageSize=1000`);
    }
);

export const fetchMemberUpgradeApps = createAsyncThunk(
    "membershipUpgrade/fetchMemberUpgradeApps",
    async (status, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/MembershipUpgrade/applications${objToParams(getState()?.membershipUpgrade?.memberUpgradeAppsParams)}`);
    }
);

export const memberUpgradeApprove = createAsyncThunk(
    "membershipUpgrade/membershipUpgradeApprove",
    async ({arg, id}, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/MembershipUpgrade/${id}/approve`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);


const membershipUpgradeSlice = createSlice({
    name: "membershipUpgrade", 
    initialState,
    reducers: {
        setMemberUpgradeDetails: (state, { payload }) => {
            state.memberUpgradeDetails = payload;
        },

        setUpdateMemberUpgradeAppsStatus:(state, {payload}) => {
            state.memberUpgradeAppsParams.status = payload;
            state.memberUpgradeApps = [];
          },
          setUpdateMemberUpgradeAppsPagination:(state, {payload}) => {
            state.memberUpgradeAppsParams.page = payload;
            state.memberUpgradeApps = [];
          },
          setUpdateMemberUpgradeAppsPageSize:(state, {payload}) => {
            state.memberUpgradeAppsParams.pageSize = payload;
            state.memberUpgradeApps = [];
          },
    },
    extraReducers: (builder) => {
        // get all membership upgrade
        builder.addCase(fetchMembershipUpgrade.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchMembershipUpgrade.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.membershipUpgrade = payload;
            state.error = null;
        });

        builder.addCase(fetchMembershipUpgrade.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });


        // get all membership upgrade applications 
        builder.addCase(fetchMemberUpgradeApps.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchMemberUpgradeApps.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.memberUpgradeApps = payload;
            state.error = null;
        });

        builder.addCase(fetchMemberUpgradeApps.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });


        // membership upgrade approve

        builder.addCase(memberUpgradeApprove.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(memberUpgradeApprove.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(memberUpgradeApprove.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });
    }
});

export const {setMemberUpgradeDetails, setUpdateMemberUpgradeAppsStatus, setUpdateMemberUpgradeAppsPagination, setUpdateMemberUpgradeAppsPageSize} = membershipUpgradeSlice.actions;
export default membershipUpgradeSlice.reducer;