import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";


const initialState = {
    loading: false,
    error: null,
    lookupRole: [],
    lookupDepartment: [],
    lookupFee: [],
    lookupEvent: [],
    lookupApprovalSetup: [],
    lookupExamCategory:[],
    lookupExamDiet:[],
    lookupExamPeriod:[],
    lookupExamSubject:[],
    lookupExamCentre:[],
    lookupEntryQualification:[],
};


export const fetchLookupRole = createAsyncThunk(
    "lookup/fetchLookupRole",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/Role`);
    }
);

export const fetchLookupDepartment = createAsyncThunk(
    "lookup/fetchLookupDepartment",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/Department`);
    }
);

export const fetchLookupFee = createAsyncThunk(
    "lookup/fetchLookupFee",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/Fee`);
    }
);

export const fetchLookupEvent = createAsyncThunk(
    "lookup/fetchLookupEvent",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/Event`);
    }
);

export const fetchLookupApprovalSetup = createAsyncThunk(
    "lookup/fetchLookupApprovalSetup",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/ApprovalSetup`);
    }
);

export const fetchLookupExamCategory = createAsyncThunk(
    "lookup/fetchLookupExamCategory",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/ExamCategory`);
    }
);

export const fetchLookupExamDiet = createAsyncThunk(
    "lookup/fetchLookupExamDiet",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/ExamDiet`);
    }
);

export const fetchLookupExamPeriod = createAsyncThunk(
    "lookup/fetchLookupExamPeriod",
    async (dietId) => {
        return httpRequest(`${BASE_URL}/v2/Lookup/ExamPeriod?dietId=${dietId}`);
    }
);

export const fetchLookupExamSubject = createAsyncThunk(
    "lookup/fetchLookupExamSubject",
    async (examCategoryId) => {
        return httpRequest(`${BASE_URL}/v2/Lookup/ExamSubject?examCategoryId=${examCategoryId}`);
    }
);

export const fetchLookupExamCentre = createAsyncThunk(
    "lookup/fetchLookupExamCentre",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/ExamCentre`);
    }
);

export const fetchLookupEntryQualification = createAsyncThunk(
    "lookup/fetchLookupEntryQualification",
    async (examCategoryId) => {
        return httpRequest(`${BASE_URL}/v2/Lookup/EntryQualification?examCategoryId=${examCategoryId}`);
    }
);


const lookupSlice = createSlice({
    name: "lookup",
    initialState,
    extraReducers: (builder) => {
        // get lookup role
        builder.addCase(fetchLookupRole?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupRole?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupRole = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupRole?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup department
        builder.addCase(fetchLookupDepartment?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupDepartment?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupDepartment = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupDepartment?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup fee
        builder.addCase(fetchLookupFee?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupFee?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupFee = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupFee?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup event
        builder.addCase(fetchLookupEvent?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupEvent?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupEvent = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupEvent?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup approval setup
        builder.addCase(fetchLookupApprovalSetup?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupApprovalSetup?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupApprovalSetup = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupApprovalSetup?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup exam category
        builder.addCase(fetchLookupExamCategory?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupExamCategory?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupExamCategory = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupExamCategory?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup exam diet
        builder.addCase(fetchLookupExamDiet?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupExamDiet?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupExamDiet = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupExamDiet?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup exam period
        builder.addCase(fetchLookupExamPeriod?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupExamPeriod?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupExamPeriod = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupExamPeriod?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup exam subject
        builder.addCase(fetchLookupExamSubject?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupExamSubject?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupExamSubject = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupExamSubject?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup exam centre
        builder.addCase(fetchLookupExamCentre?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupExamCentre?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupExamCentre = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupExamCentre?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup entry qualification
        builder.addCase(fetchLookupEntryQualification?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupEntryQualification?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupEntryQualification = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupEntryQualification?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
    }
});


export default lookupSlice.reducer;