import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { useState } from 'react';
import PendingTable from './PendingTable';
import ApprovalTable from './ApprovalTable';
import DeclinedTable from './DeclinedTable';
import { useEffect } from 'react';
import {fetchMemberUpgradeApps, setUpdateMemberUpgradeAppsStatus, setUpdateMemberUpgradeAppsPagination, setUpdateMemberUpgradeAppsPageSize} from "../redux/slices/membershipUpgradeSlice";
import { useDispatch, useSelector } from 'react-redux';

function MembershipUpgradeTab() {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (index) => {
        setSelectedTab(index);
        dispatch(setUpdateMemberUpgradeAppsStatus(index === 0 ? "Pending" : index === 1 ? "Approved" : "Declined"))
    };

    const memberUpgradeApps = useSelector((state) => state?.membershipUpgrade?.memberUpgradeApps);
    const data = useSelector((state) => state?.membershipUpgrade);
    
    const isloading = data?.loading;
    const iserror = data?.error;

    useEffect(() =>{
        dispatch(fetchMemberUpgradeApps());
    }, [dispatch, data?.memberUpgradeAppsParams ]);
    
    const handlePagination = (page) =>{
    dispatch(setUpdateMemberUpgradeAppsPagination(page));
    };

    const handlePageSize = (size) => {
    dispatch(setUpdateMemberUpgradeAppsPageSize(size));
    };

    const MemberUpgradeAppsData = {
    handlePagination,
    handlePageSize,
    itemsPerPage:data?.memberUpgradeApps?.currentPageRecord,
    totalRecord: data?.memberUpgradeApps?.totalRecord,
    pageNumber: data?.memberUpgradeAppsParams?.page,
    nextPage: data?.memberUpgradeApps?.nextPage,
    previousPage: data?.memberUpgradeApps?.previousPage,
    pageSize: data?.memberUpgradeAppsParams?.pageSize,
    }

    return (
        <Tabs variant='unstyled'>
            <TabList gap={3} mb={3}>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(0)}
                    style={selectedTab === 0 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Pending
                </Tab>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(1)}
                    style={selectedTab === 1 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Approved
                </Tab>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(2)}
                    style={selectedTab === 2 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Declined
                </Tab>
            </TabList>
            <TabPanels bgColor={'white'} minH={'100vh'}>
                <TabPanel>
                   <PendingTable memberUpgradeApps={memberUpgradeApps} isloading={isloading} iserror={iserror} {...MemberUpgradeAppsData} />
                </TabPanel>
                <TabPanel>
                    <ApprovalTable memberUpgradeApps={memberUpgradeApps} isloading={isloading} iserror={iserror} {...MemberUpgradeAppsData} />
                </TabPanel>
                <TabPanel>
                   <DeclinedTable memberUpgradeApps={memberUpgradeApps} isloading={isloading} iserror={iserror} {...MemberUpgradeAppsData} />
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default MembershipUpgradeTab;
