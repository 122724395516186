import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text, Button, Menu, MenuButton, MenuItem, MenuList, Alert,AlertIcon, AlertTitle  } from '@chakra-ui/react';
import { BiLeftArrowAlt } from "react-icons/bi";
import { PiExport } from "react-icons/pi";
import {BsThreeDotsVertical} from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import Create from '../components/layout/Create';
import {fetchExamSubject, setEditExamSubject, setUpdateExamSubjectPagination, setUpdateExamSubjectPageSize} from "../redux/slices/examCategorySubjectSlice";
import { checkPermissions} from '../components/CheckPermission';
import SortBy from '../components/SortBy';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from '../components/loaders/DotLoader';
import CustomTablePage2 from '../components/CustomTablePage2';

const ExamSubjects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const examCategoryDetails = useSelector((state) => state?.examCategorySubject?.examCategoryDetails);
  const examSubjectData = useSelector((state) => state?.examCategorySubject);

  const getAllExamSubject = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchExamSubject(categoryId));
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Exam Subject, please check your request/network");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Exam Subject, please check your network");
    }
  }, [dispatch, categoryId]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllExamSubject();
  }, [dispatch, getAllExamSubject, examSubjectData?.examSubjectParams]);

  const addSubjectPermission = checkPermissions( 'Exam Setup', 'Exam Categories', 'Create', 'Granted' );
  const editSubjectPermission = checkPermissions( 'Exam Setup', 'Exam Categories', 'Edit', 'Granted' );
  const deleteSubjectPermission = checkPermissions( 'Exam Setup', 'Exam Categories', 'Delete', 'Granted' );

  const createData = {
      onClick: () => { dispatch(setFormModal({status:true, title: "Add Subject ", type:"AddSubject"})); },
      createText: "Add Subject ",
  }

  const handlePagination = (page) =>{
    dispatch(setUpdateExamSubjectPagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateExamSubjectPageSize(size));
  };

  const handleEdit = (item) => {
    dispatch(setEditExamSubject(item));
    dispatch(setFormModal({status:true, title: "Edit Subject", type:"editSubject"}));
  }

  const tableProps = {
    data: examSubjectData?.examSubject?.data ,
    title: {
      subjectName: "Subject Name",
      categoryCode:"Subject Code",
      categoryName:"Category",
      Action:'',
    },
    dataKeys: [
      "subjectName",
      "categoryCode",
      "categoryName",
      "Action",
    ],
    handlePagination,
    handlePageSize,
    itemsPerPage:examSubjectData?.examSubject?.currentPageRecord,
    totalRecord: examSubjectData?.examSubject?.totalRecord,
    pageNumber: examSubjectData?.examSubjectParams?.page,
    nextPage: examSubjectData?.examSubject?.nextPage,
    previousPage: examSubjectData?.examSubject?.previousPage,
    pageSize: examSubjectData?.examSubjectParams?.pageSize,
      
    transformRow: (item) => ({
      subjectName: <Box style={{cursor:'pointer'}}>{item?.name}</Box>,
      categoryCode: <Box style={{cursor:'pointer'}}>{item?.code}</Box>,
      categoryName: <Box style={{cursor:'pointer'}}>{item?.categoryName}</Box>,
      Action: item && (
        <Menu>
          {
            !editSubjectPermission && !deleteSubjectPermission ? null : (
              <MenuButton>
                <BsThreeDotsVertical />
              </MenuButton>
            )
          }
          <MenuList >
            {
              editSubjectPermission? (
                <MenuItem onClick={() => handleEdit(item)} > Edit </MenuItem>
              ) : null
            }
            {
              deleteSubjectPermission? (
                <MenuItem > Delete </MenuItem>
              ) : null
            }
          </MenuList>
        </Menu>
      )
    }),
  };
    
  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={4} mb={5} cursor={'pointer'}>
          <Flex alignItems={'center'} gap={'20px'} onClick={() => navigate(-1)} >
              <Box bg={'#F9A36238'} rounded={'full'} p={1}>
                  <BiLeftArrowAlt color="#D8991F" size={'20px'} />
              </Box>
              <Text fontSize={'18px'} fontWeight={'700'} color={'#222B45'} opacity={'0.5'}>Exam Subject / {examCategoryDetails && examCategoryDetails?.name}</Text>
          </Flex>
          <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
              {
                addSubjectPermission?  (
                  <Create {...createData} />
                ) : null
              }
              <SortBy />
              <Button leftIcon={<PiExport style={{width: "15px", height: "15px"}} />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
                fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
              >
                Export
              </Button>
          </Flex>
      </Flex>
      {
        loading ? (
          <Flex justifyContent={"center"} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ): (
          <>
            {
            error ? (
              <Alert status="error" className="mt-3" mb={3}>
                  <AlertIcon />
                  <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
              </Alert>
              ) 
              : (<CustomTablePage2 {...tableProps} />)
            }
          </>
        )
      }
    </Box>
  )
}

export default ExamSubjects;