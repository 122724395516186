import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { fetchExamCenterChangeDetails } from '../redux/slices/examMgtSlice';
import { Alert, AlertIcon, AlertTitle, Box, Flex } from '@chakra-ui/react';
import DotLoader from './loaders/DotLoader';
import { setloadTost } from '../redux/slices/toastSlice';
import CustomTablePage2 from './CustomTablePage2';

const ExamCentreDeclinedTable = ({examCentreChangeApplication, loading, error, errorsArray, handlePagination ,handlePageSize ,itemsPerPage,totalRecord,pageNumber,nextPage, previousPage, pageSize}) => {
    const dispatch = useDispatch();
    const [loadingDetails, setLoadingDetails] = useState({});
  
    const handleUpdate = async (item) => {
      setLoadingDetails((prev) => ({ ...prev, [item.id]: true }));
      try {
        const response = await dispatch(fetchExamCenterChangeDetails(item?.id));
        if (response?.payload?.isSuccess === true) {
        //   dispatch(setFormModal({ status: true, title: "Member Application Details", type: "individualDetails", size:"3xl", data:response?.payload }));
        }else if (response?.payload?.name === "AxiosError"){
          dispatch(setloadTost({ title: 'Failed to view details', description: `${response?.payload?.errors?.map((errors) => errors)  || response?.payload?.error?.description || `Failed to View ${item?.fullName} Details, please try again`}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
        }
      } catch (error) {
        dispatch(setloadTost({ title: 'Failed to view details', description: `${error?.payload?.message || `Failed to View ${item?.fullName} Details, please try again`}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
      }finally {
        setLoadingDetails((prev) => ({ ...prev, [item.id]: false }));
      }
  
    };
  
    const tableProps = {
      data: examCentreChangeApplication?.data,
      title: {
        fullName: "Fullname",
        appNo: "Application Number",
        email: "Email Address",
        dateRegistered: "Date Registered",
        view: "",
      },
      dataKeys: ["fullName", "appNo", "email", "dateRegistered", "view"],
      handlePagination,
      handlePageSize,
      itemsPerPage,
      totalRecord,
      pageNumber,
      nextPage,
      previousPage,
      pageSize,
  
      transformRow: (item) => ({
        fullName: <Box style={{ cursor: "pointer" }}>{item?.fullName}</Box>,
        appNo: <Box style={{ cursor: "pointer" }}>{item?.applicationNumber}</Box>,
        email: <Box style={{ cursor: "pointer" }}>{item?.email}</Box>,
        dateRegistered: (
          <Box style={{ cursor: "pointer" }}>{item?.dateRegistered}</Box>
        ),
        view: item &&  (
          <Box
            style={{ cursor: "pointer" }}
            color={"#FFFFFF"}
            bg={"#812773"}
            borderRadius={"5px"}
            p={"10px 15px"}
            textAlign={"center"}
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"16.94px"}
            fontFamily={"inter"}
            onClick={() => handleUpdate(item)}
          >
            {loadingDetails[item.id] ? 'Loading...' : "View Details"}
          </Box>
        ),
      }),
    };
    return (
      
      <>
        {
        loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ): (
          <>
            {
            error ? (
              <Alert status="error" className="mt-3" mb={3}>
                  <AlertIcon />
                  <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
              </Alert>
              ) 
              : (<CustomTablePage2 {...tableProps} />)
            }
          </>
        )
      }
      </>
    )
  }

export default ExamCentreDeclinedTable