import React from 'react'
import { Box, Flex, Text, Select, FormControl, Button } from '@chakra-ui/react';
import {  useNavigate } from 'react-router-dom';
import { PiExport } from 'react-icons/pi';
import CustomTablePage2 from './CustomTablePage2';

function MemberReportTable({memberReport, handlePagination ,handlePageSize ,itemsPerPage,totalRecord,pageNumber,nextPage, previousPage, pageSize}) {
    const navigate = useNavigate();
    const handleUpdate = (item) => {
      console.log("item", item)
      navigate(`/report/memberreport/member-profile?membershipNo=${item?.membershipNumber}`);
    }

    const tableProps = {
        data: memberReport,
        title: {
            membershipNo: 'Membership No',
            fullName: "Full Name",
            gender: "Gender",
            qualification: "Qualification",
            qualificationType: "Member Category",
        },
        dataKeys: [
            "membershipNo",
            "fullName",
            "gender",
            "qualification",
            "qualificationType",
        ],
        handlePagination,
        handlePageSize,
        itemsPerPage,
        totalRecord,
        pageNumber,
        nextPage,
        previousPage,
        pageSize,
        transformRow: (item) => ({
            membershipNo: <Box onClick={() =>handleUpdate(item)} style={{ cursor: 'pointer' }}>{item?.membershipNumber}</Box>,
            fullName: <Box onClick={() =>handleUpdate(item)} style={{ cursor: 'pointer' }}>{item?.fullName}</Box>,
            gender: <Box onClick={() =>handleUpdate(item)} style={{ cursor: 'pointer' }}>{item?.gender}</Box>,
            qualification: <Box onClick={() =>handleUpdate(item)} style={{ cursor: 'pointer' }}>{item?.qualification}</Box>,
            qualificationType: <Box onClick={() =>handleUpdate(item)} style={{ cursor: 'pointer' }}>{item?.grade}</Box>,
        }),
    };
    return (
        <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
        <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
          <Text fontSize={'20px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'} >Member Report</Text>
          <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[null, null, null]}>
            <Flex alignItems={'center'}>
              <FormControl w={'100px'}>
                <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                  <option value='option1'>Option 1</option>
                  <option value='option2'>Option 2</option>
                  <option value='option3'>Option 3</option>
                </Select>
              </FormControl>
            </Flex>
            <Button leftIcon={<PiExport style={{ width: "15px", height: "15px" }} />} bg={'transparent'} _hover={{ backgroundColor: "transparent" }} _active={{ backgroundColor: "transparent" }} color={'#404040'}
              fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'}
            >
              Export
            </Button>
          </Flex>
        </Flex>
        <CustomTablePage2 {...tableProps} />
      </Box>
    )
}

export default MemberReportTable