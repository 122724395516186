import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFormModal } from '../redux/slices/modalSlice';
import { Form, Formik } from 'formik';
import {AddQualificationTypeSchema } from '../utils/formValidationSchema';
import {Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react';
import { setloadTost } from '../redux/slices/toastSlice';
import {AddEntryQualificationType, fetchEntryQualificationType, editEntryQualificationType} from "../redux/slices/entryQualificationTypeSlice";
import {fetchLookupExamCategory} from "../redux/slices/lookupSlice";

const AddQualificationType = ({data}) => {
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchLookupExamCategory());
    }, [dispatch]);
    const examCategoryData = useSelector((state) => state?.lookup?.lookupExamCategory);

    const initialValues = {
        name: data?.name ||  '',
        description: data?.description ||'',
        examCategory: data?.examCategory?.id.toUpperCase() || '',
    }
    const handleSubmit = async (values) => {
        let response;
        const formData = {
            name: values.name,
            description: values.description,
            examCategoryId: values?.examCategory,
        };
        if(data?.id){
            response = await dispatch(editEntryQualificationType({id:data?.id, payload:formData}));
        }else{
            response = await dispatch(AddEntryQualificationType(formData));
        }

        if (response?.payload?.isSuccess === true) {
            dispatch(fetchEntryQualificationType());
            dispatch(setFormModal({ status: false, data: null }));
            dispatch(setloadTost({ title: 'Successs', description: `Qualification type has been added successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
            dispatch(setloadTost({ title: 'Failed', description: `${response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to add qualification type"}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
        }
    }
    
  return (
    <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddQualificationTypeSchema}
        onSubmit={(values) => handleSubmit(values)}
        >
        {({
            errors, touched, values, handleBlur, handleChange, isSubmitting,
        }) => {
            return(
            <Form>
                <FormControl mt={4} isInvalid={errors.name && touched.name} color="#121212">
                    <FormLabel htmlFor='name' fontSize={'16px'}>Name</FormLabel>
                    <Input
                        placeholder="Enter Here"
                        fontSize={"small"}
                        bg={'#F3F3F3'}
                        name="name"
                        focusBorderColor="#ffffff"
                        borderRadius={'0'}
                        borderColor={"#FFFFFF"}
                        _placeholder={{ color: "#B4B1B1" }}
                        _hover={{ borderColor: "#FFFFFF" }}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.name}
                    />
                    {touched.name && errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                    )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.description && touched.description} color="#121212">
                    <FormLabel htmlFor='description' fontSize={'16px'}>Description</FormLabel>
                    <Input
                        placeholder="Enter Here"
                        fontSize={"small"}
                        bg={'#F3F3F3'}
                        name="description"
                        focusBorderColor="#ffffff"
                        borderRadius={'0'}
                        borderColor={"#FFFFFF"}
                        _placeholder={{ color: "#B4B1B1" }}
                        _hover={{ borderColor: "#FFFFFF" }}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.description}
                    />
                    {touched.description && errors.description && (
                        <FormErrorMessage>{errors.description}</FormErrorMessage>
                    )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.examCategory && touched.examCategory} color="#121212">
                    <FormLabel htmlFor='feeType' fontSize={'16px'}>Exam Category</FormLabel>
                    <Select
                        name="examCategory"
                        placeholder='Select Exam Category'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        bg={'#F3F3F3'}
                        borderRadius={"0"}
                        focusBorderColor="#ffffff"
                        borderColor={"#FFFFFF"}
                        _hover={{ borderColor: "#FFFFFF" }}
                        _placeholder={{ color: "#B4B1B1" }}
                        value={values?.examCategory}
                    >
                        {
                            examCategoryData  && examCategoryData?.map((item, index) => (
                                <option key={index} value={item?.value}>{item?.text}</option>
                            ))
                        }
                    </Select>
                    {touched.examCategory && errors.examCategory && (
                        <FormErrorMessage>{errors.examCategory}</FormErrorMessage>
                    )}
                </FormControl>
                
                <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                    <Button
                        mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                        width={"118px"}
                        background={"#D11A2A"}
                        _hover={{ background: " " }}
                        color={"#FFFFFF"}
                        type="submit"
                        isLoading={isSubmitting}
                        loadingText="Saving ..."
                    >
                        Save 
                    </Button>
                    <Button
                        mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                        width={"118px"}
                        background={""}
                        _hover={{ background: " " }}
                        color={"#8F9BB3"}
                        onClick={() => {
                            dispatch(setFormModal({ status: false, data: null }));
                        }}
                        loadingText="Saving ..."
                    >
                        Cancel
                    </Button>
                </Flex>
            </Form>
            )
        }}
    </Formik>
  )
}

export default AddQualificationType;