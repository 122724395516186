import React from "react";
import { Box,Flex, Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
// import { useDispatch } from "react-redux";
// import { setFormModal } from "../redux/slices/modalSlice";
import DotLoader from '../components/loaders/DotLoader';
import CustomTablePage2 from "./CustomTablePage2";

const StudentApprovedTable = ({studentApplication, loading, error, errorsArray, handlePagination ,handlePageSize ,itemsPerPage,totalRecord,pageNumber,nextPage, previousPage, pageSize}) => {
  // const dispatch = useDispatch();

  // const handleUpdate = (item) => {
  //   dispatch(setFormModal({ status: true, title: "Member Application Details", type: "individualDetails", size:"3xl", data:item }));
  // };

  const tableProps = {
    data: studentApplication?.data,
    title: {
      fullName: "Fullname",
      appNo: "Application Number",
      email: "Email Address",
      dateRegistered: "Date Registered",
      // view: "",
    },
    dataKeys: ["fullName", "appNo", "email", "dateRegistered"],
    handlePagination,
    handlePageSize,
    itemsPerPage,
    totalRecord,
    pageNumber,
    nextPage,
    previousPage,
    pageSize,
    transformRow: (item) => ({
      fullName: <Box style={{ cursor: "pointer" }}>{item?.fullName}</Box>,
      appNo: <Box style={{ cursor: "pointer" }}>{item?.applicationNumber}</Box>,
      email: <Box style={{ cursor: "pointer" }}>{item?.email}</Box>,
      dateRegistered: (
        <Box style={{ cursor: "pointer" }}>{item?.dateRegistered}</Box>
      ),
      // view: item &&  (
      //   <Box
      //     style={{ cursor: "pointer" }}
      //     color={"#FFFFFF"}
      //     bg={"#812773"}
      //     borderRadius={"5px"}
      //     p={"10px 15px"}
      //     textAlign={"center"}
      //     fontSize={"14px"}
      //     fontWeight={"400"}
      //     lineHeight={"16.94px"}
      //     fontFamily={"inter"}
      //     onClick={() => handleUpdate(item)}
      //   >
      //     View Details
      //   </Box>
      // ),
    }),
  };

  return (
    <>
      {
      loading ? (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <DotLoader />
        </Flex>
      ): (
        <>
          {
          error ? (
            <Alert status="error" className="mt-3" mb={3}>
                <AlertIcon />
                <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
            ) 
            : (<CustomTablePage2 {...tableProps} />)
          }
        </>
      )
    }
    </>
  )
}

export default StudentApprovedTable;