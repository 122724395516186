import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Text, Select, FormControl, Menu, MenuButton, MenuList, MenuItem, Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
import { setFormModal } from '../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvent, setEditEvents, setEventDetails, setUpdateEventsPagination, setUpdateEventsPageSize } from '../redux/slices/eventSlice';
import { checkPermissions } from '../components/CheckPermission';
import { format } from 'date-fns';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { clearsearchFilter } from '../redux/slices/searchFilterSlice';
import { BASE_URL } from '../config/settings';
import httpRequest from '../utils/httpRequest';
import { setloadTost } from '../redux/slices/toastSlice';
import DotLoader from '../components/loaders/DotLoader';
import Create from '../components/layout/Create';
import CustomTablePage2 from '../components/CustomTablePage2';

const EventManagement = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const eventsData = useSelector((state) => state?.event);
  console.log("eventsData", eventsData);

  const getAllEvent = useCallback(async () => {
    setError("");
    setLoading(true);
    try {
      const response = await dispatch(fetchEvent());
      if (response?.payload?.name === "AxiosError") {
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Events");
      }
    } catch (error) {
      setLoading(false);
      setError(error?.payload?.message || "Failed to get Events, please check your network");
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllEvent();
  }, [dispatch, getAllEvent, eventsData?.eventsParams]);

  const addEventsPermission = checkPermissions('Event Management', 'Events', 'Create', 'Granted');
  const editEventsPermission = checkPermissions('Event Management', 'Events', 'Edit', 'Granted');
  const deleteEventsPermission = checkPermissions('Event Management', 'Events', 'Delete', 'Granted');

  const handlePagination = (page) =>{
    dispatch(setUpdateEventsPagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateEventsPageSize(size));
  };

  const createData = {
    onClick: () => { dispatch(setFormModal({ status: true, title: "Add Event", type: "addEvent", })); },
    createText: "Add Event",
  }

  const handleViewEventDetails = (item) => {
    dispatch(setEventDetails(item))
    dispatch(setFormModal({ status: true, title: "Event Details", type: "viewEventDetails", size: "4xl" }));
  }

  const handleEdit = (item) => {
    dispatch(setEditEvents(item))
    dispatch(setFormModal({ status: true, title: "Edit Event", type: "editEvent" }))
  }

  const handleDelete = async (item) => {
    setError("");
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Event/${item?.id}/delete`,
        "post",
      );
      if (response?.isSuccess === true) {
        setError('');
        dispatch(fetchEvent());
        dispatch(setloadTost({ title: 'Event Deleted', description: 'Event deleted successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      } else {
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete fee");
      }
    } catch (error) {
      setError(error?.payload?.message || "Failed to delete event, please check your network");
    }
  }

  const isEventFinished = (endDate) => {
    return new Date(endDate) < new Date();
  }

  const tableProps = {
    data: eventsData?.events?.data,
    title: {
      eventName: "Event Name",
      startDate: "Start Date",
      endDate: "End Date",
      statusUpdate: "Status",
      viewDetails: "",
      action: "",
    },
    dataKeys: [
      "eventName",
      "startDate",
      "endDate",
      "statusUpdate",
      "viewDetails",
      "action",
    ],
    handlePagination,
    handlePageSize,
    itemsPerPage:eventsData?.events?.currentPageRecord,
    totalRecord: eventsData?.events?.totalRecord,
    pageNumber: eventsData?.eventsParams?.page,
    nextPage: eventsData?.events?.nextPage,
    previousPage: eventsData?.events?.previousPage,
    pageSize: eventsData?.eventsParams?.pageSize,
    transformRow: (item) => ({
      eventName: item?.eventName,
      startDate: item?.startDate && format(new Date(item?.startDate), 'yyyy-MM-dd'),
      endDate: item?.endDate && format(new Date(item?.endDate), 'yyyy-MM-dd'),
      statusUpdate: item && <Box style={{ cursor: 'pointer' }} color={getStatusColor(item?.status)}>{item?.status}</Box>,
      viewDetails: item && (
        <Button onClick={() => handleViewEventDetails(item)} fontWeight={'bold'} fontSize={'14px'} bgColor={'#812773'} color={'white'} px={'10px'} _hover={{ bgColor: '' }} >View Details</Button>
      ),
      action: item && (
        <Menu px={'10px'}>
          {
            !editEventsPermission && !deleteEventsPermission ? null : (
              <MenuButton>
                <BsThreeDotsVertical />
              </MenuButton>
            )
          }
          <MenuList >
            {
              editEventsPermission && !isEventFinished(item.endDate) ? (
                <MenuItem
                  onClick={() => handleEdit(item)}>Edit</MenuItem>
              ) : null
            }
            {
              deleteEventsPermission ? (
                <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
              ) : null
            }
          </MenuList>
        </Menu>
      )
    }),
  };

  const getStatusColor = (statusUpdate) => {
    switch (statusUpdate) {
      case 'UpComing':
        return '#FFC34F';
      case 'Ongoing':
        return '#0ADB13';
      case 'Finished':
        return '#D73439'; 
      default:
        return '';
    }
  };

  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFFFFF'}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'}>All Events</Text>
        <Flex flexDirection={["column", "column", "row"]} justifyContent={'center'} alignItems={'center'} gap={[3, null, null]} >
          {
            addEventsPermission ? (
              <Create {...createData} />
            ) : null
          }
          <Flex alignItems={'center'}>
            <FormControl w={'100px'}>
              <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                <option value='option1'>Option 1</option>
                <option value='option2'>Option 2</option>
                <option value='option3'>Option 3</option>
              </Select>
            </FormControl>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
              <g clip-path="url(#clip0_26_2208)">
                <path d="M7.6 0.344238C6.365 0.344238 5.32 1.14224 4.921 2.24424H0V4.14424H4.921C5.3105 5.24624 6.3555 6.04424 7.6 6.04424C9.1675 6.04424 10.45 4.76174 10.45 3.19424C10.45 1.62674 9.1675 0.344238 7.6 0.344238ZM12.35 2.24424V4.14424H19V2.24424H12.35ZM12.35 6.99424C11.115 6.99424 10.07 7.79224 9.671 8.89424H0V10.7942H9.671C10.0605 11.8962 11.1055 12.6942 12.35 12.6942C13.9175 12.6942 15.2 11.4117 15.2 9.84424C15.2 8.27674 13.9175 6.99424 12.35 6.99424ZM17.1 8.89424V10.7942H19V8.89424H17.1ZM4.75 13.6442C3.515 13.6442 2.47 14.4422 2.071 15.5442H0V17.4442H2.071C2.4605 18.5462 3.5055 19.3442 4.75 19.3442C6.3175 19.3442 7.6 18.0617 7.6 16.4942C7.6 14.9267 6.3175 13.6442 4.75 13.6442ZM9.5 15.5442V17.4442H19V15.5442H9.5Z" fill="#404040" />
              </g>
              <defs>
                <clipPath id="clip0_26_2208">
                  <rect width="19" height="19" fill="white" transform="translate(0 0.344238)" />
                </clipPath>
              </defs>
            </svg>
          </Flex>
        </Flex>
      </Flex>
      {
        loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ) : (
          <>
            {
              error ? (
                <Alert status="error" className="mt-3" mb={3}>
                  <AlertIcon />
                  <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                </Alert>
              ) : (<CustomTablePage2 {...tableProps} />)
            }
          </>
        )
      }
    </Box>
  )
}

export default EventManagement;
