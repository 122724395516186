import { Box, Flex, FormControl, Select, Text } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DotLoader from "./loaders/DotLoader";
import CustomTablePage2 from "./CustomTablePage2";
import { setUpdateDuesManagementPaymentHistoryPagination, setUpdateDuesManagementPaymentHistoryPageSize } from "../redux/slices/membershipManagementSlice";


const PaymentHistory = () => {
    const dispatch = useDispatch();

  const memberProfileData = useSelector((state) => state?.membershipManagement);
  const duesData = useSelector((state) => state?.membershipManagement);

  const handlePaginationHistory = (page) => {
    dispatch(setUpdateDuesManagementPaymentHistoryPagination(page));
  };

  const handlePageSizeHistory = (size) => {
    dispatch(setUpdateDuesManagementPaymentHistoryPageSize(size));
  };

  const tablePropsPaymentHistory = {
    data: memberProfileData?.duesManagementPaymentHistory?.data,
    title: {
      date: "Date",
      payment: "Payment",
      amountPaid: "Amount Paid",
      paymentMethod: "Payment Method",
      status: "Status",
    },
    dataKeys: ["date", "payment", "amountPaid", "paymentMethod", "status"],
    handlePagination: handlePaginationHistory,
    handlePageSize: handlePageSizeHistory,
    itemsPerPage:
      memberProfileData?.duesManagementPaymentHistory?.currentPageRecord,
    totalRecord: memberProfileData?.duesManagementPaymentHistory?.totalRecord,
    pageNumber: memberProfileData?.duesManagementHistoryParams?.page,
    nextPage: memberProfileData?.duesManagementPaymentHistory?.nextPage,
    previousPage: memberProfileData?.duesManagementPaymentHistory?.previousPage,
    pageSize: memberProfileData?.duesManagementHistoryParams?.pageSize,
    transformRow: (item) => ({
      date: item?.date && item?.date,
      payment: item?.payment,
      amountPaid: item?.amountPaid,
      paymentMethod: item?.paymentMethod,
      status: item?.status,
    }),
  };

  return (
    <Box my={12} bgColor={"#FFF"}>
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={"20px"}
        px={"20px"}
        borderBottom={"1px solid #B4B1B1"}
        pb={2}
        mb={5}
      >
        <Text
          fontSize={"20px"}
          fontWeight={"700"}
          color={"#D11A2A"}
          opacity={"50%"}
        >
          Payment History
        </Text>
        <Flex
          flexDirection={["column", "column", "row"]}
          alignItems={"center"}
          gap={[null, null, null]}
        >
          <Flex alignItems={"center"}>
            <FormControl w={"100px"}>
              <Select
                placeholder="sort by"
                border={"0"}
                color={"#404040"}
                fontSize={"14px"}
                fontWeight={"500"}
                lineHeight={"16px"}
                focusBorderColor="white"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </FormControl>
          </Flex>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
          >
            <g clip-path="url(#clip0_26_2208)">
              <path
                d="M7.6 0.344238C6.365 0.344238 5.32 1.14224 4.921 2.24424H0V4.14424H4.921C5.3105 5.24624 6.3555 6.04424 7.6 6.04424C9.1675 6.04424 10.45 4.76174 10.45 3.19424C10.45 1.62674 9.1675 0.344238 7.6 0.344238ZM12.35 2.24424V4.14424H19V2.24424H12.35ZM12.35 6.99424C11.115 6.99424 10.07 7.79224 9.671 8.89424H0V10.7942H9.671C10.0605 11.8962 11.1055 12.6942 12.35 12.6942C13.9175 12.6942 15.2 11.4117 15.2 9.84424C15.2 8.27674 13.9175 6.99424 12.35 6.99424ZM17.1 8.89424V10.7942H19V8.89424H17.1ZM4.75 13.6442C3.515 13.6442 2.47 14.4422 2.071 15.5442H0V17.4442H2.071C2.4605 18.5462 3.5055 19.3442 4.75 19.3442C6.3175 19.3442 7.6 18.0617 7.6 16.4942C7.6 14.9267 6.3175 13.6442 4.75 13.6442ZM9.5 15.5442V17.4442H19V15.5442H9.5Z"
                fill="#404040"
              />
            </g>
            <defs>
              <clipPath id="clip0_26_2208">
                <rect
                  width="19"
                  height="19"
                  fill="white"
                  transform="translate(0 0.344238)"
                />
              </clipPath>
            </defs>
          </svg>
        </Flex>
      </Flex>
      {duesData?.loading ? (
        <Flex justifyContent={"center"} alignItems={"center"}>
          <DotLoader />
        </Flex>
      ) : (
        <CustomTablePage2 {...tablePropsPaymentHistory} />
      )}
    </Box>
  );
};

export default PaymentHistory;
